import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { LoadAnimation } from "../../../../../_metronic/helpers/functions/swal/LoadAnimation";
import { IBank, optionsBaixarBanks, optionsBillingBanks, optionsEmissaoBoleto, optionsProtestarBanks, optionsStatusBank, optionsTitulosBanks } from "../../../../interface/I_Configurations";
import { NumericFormat } from "react-number-format";
import Select from "react-select";
import { useAuth } from "../../../../modules/auth";
import { getBeneficiary, saveBank } from "../../../../request/configurations/_request_banks";
import { CaixaEconomica } from "./form_register_bank/CaixaEconomica";
import { Itau } from "./form_register_bank/Itau";
import { Bradesco } from "./form_register_bank/Bradesco";
import { ApiBoletoCloud } from "./form_register_bank/ApiBoletoCloud";
import { Option } from "../../../../interface/I_General";
import { SchemaRegister } from "./form_register_bank/SchemaRegister";
import * as yup from 'yup';
import { Santander } from "./form_register_bank/Santander";

export const Register = ({ dataBank }: any) => {

    const { auth } = useAuth();

    const [beneficiarys, setBeneficiarys] = useState<any>([]);

    const [formValues, setFormValues] = useState<any>(dataBank || { bank_code: "" });

    const [errors, setErrors] = useState<any>({
        beneficiary: '',
        name_apresentation: '',
        agency: '',
        account: '',
        carteira: '',
    });

    const [exibirConfiguracao, setExibirConfiguracao] = useState(false);

    useEffect(() => {
        if (formValues.bank_code === '104' || formValues.bank_code === '237' || formValues.bank_code === '341' || formValues.bank_code === 'API-BC' || formValues.bank_code === '033') {
            setExibirConfiguracao(true);
        }
    }, [])


    useEffect(() => {
        loadBanks();
    }, [])

    const loadBanks = async () => {

        if (auth && auth.api_token) {
            try {
                const response = await getBeneficiary(auth);
                if (response.data && response.data && Array.isArray(response.data)) {

                    const newListBeneficiary = response.data.map((beneficiary: { id: any; registration_type: any; cpf: string; cnpj: string; name: string; }) => ({
                        value: beneficiary.id,
                        label: beneficiary.registration_type === 'cpf' ? `${beneficiary.cpf} - ${beneficiary.name}` : `${beneficiary.cnpj} - ${beneficiary.name}`,
                    }));

                    setBeneficiarys(newListBeneficiary);
                }
            } catch (error) {
                console.error('Erro ao carregar bancos:', error);
            }

        }
    }

    const combineFormValues = (formState: any) => {

        if (formState.api_integration && Array.isArray(formState.api_integration)) {
            // const updatedApiIntegration = formState.api_integration.map((item: any) => `hbr_${item}`);
            // setFormValues((prevState:any) => ({
            //   ...prevState,
            //   ['api_integration']: updatedApiIntegration,
            // }));
        } else if (formState.action_api_integration && Array.isArray(formState.action_api_integration)) {
            setFormValues((prevState: any) => ({
                ...prevState,
                ['action_api_integration']: formState.action_api_integration,
            }));
        } else {
            setFormValues((prevState: any) => ({
                ...prevState,
                ...formState
            }));
        }
    }

    const handleInputChange = (
        value: string | number,
        fieldName: keyof IBank
    ) => {

        setFormValues({
            ...formValues,
            [fieldName]: value
        });

    };

    const handleSelectChange = <K extends keyof IBank>(selectedOption: Option | null, fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "";
        const label = selectedOption ? selectedOption.label : "";

        // Verificar o código do banco e decidir se exibir as configurações ou não

        if (fieldName === 'bank_code') {

            if (value === '104' || value === '237' || value === '341' || value === '033' || value === 'API-BC') {
                setExibirConfiguracao(true);
                setFormValues((prevState: any) => ({
                    ...prevState,
                    [fieldName]: value,
                    ['name_bank']: label || ''
                }));

            } else {
                setExibirConfiguracao(false);
                setFormValues({})
            }
        } else {

            setFormValues((prevState: any) => ({
                ...prevState,
                [fieldName]: value
            }));
        }

    };

    const confirmSaveForm = async () => {

        try {
            await SchemaRegister.validate(formValues, { abortEarly: false });
            setErrors({});
            Swal.fire({
                icon: "info",
                title: "Atenção!",
                text: 'Deseja salvar essa configuração?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, Salvar!',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    LoadAnimation('Estamos processando...');
                    saveForm();
                }
            });
        } catch (errors) {

            if (errors instanceof yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                errors.inner.forEach((error) => {

                    validationErrors[error.path as string] = error.message
                });
                setErrors(validationErrors);
            }
        }
    }

    const saveForm = async () => {

        if (auth && auth.api_token) {
            const response = await saveBank(formValues, auth);

            if (response.data.success) {
                Swal.fire({
                    icon: "success",
                    title: "Parabéns",
                    text: `Dados salvo com sucesso`,
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                });

            } else {
                Swal.fire({
                    icon: "info",
                    title: "Atenção",
                    html: `Não foi possível salvar, verifique a conexao com interne, <br> ou fale com um administrador do sistema.`,
                    showCancelButton: false,
                    confirmButtonText: 'Entendi',
                });
            }
        }
    }

    return (
        <form >
            <div className="row">
                <div className="col-md-6">
                    <div className='mb-10 row'>

                        <div className='col-md-12 mt-3'>
                            <h5 className="fs-6">SELECIONE O BANCO</h5>
                            <div className="form-floating mb-7 mt-1">
                                <div className='d-flex'>
                                    <Select
                                        styles={{ container: (base: any) => ({ ...base, zIndex: 11 }) }}
                                        className="w-100"
                                        placeholder="Banco"
                                        value={
                                            !!formValues.bank_code
                                                ? optionsBillingBanks.find((option: Option) => option.value === formValues.bank_code)
                                                : { value: '', label: 'Selecione' }
                                        }
                                        // value={formValues.bank_code !== '0' && formValues.bank_code !== '' ? { value: formValues.bank_code, label: optionsBillingBanks.find(option => option.value === formValues.bank_code)?.label } : null}
                                        onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "bank_code")}
                                        options={optionsBillingBanks}
                                    />
                                </div>
                            </div>
                        </div>

                        {
                            formValues.bank_code === '104' ? (
                                // Banco caixa economica
                                <CaixaEconomica listBeneficiary={beneficiarys} formDataEdit={formValues} combineFormValues={combineFormValues} />

                            ) : formValues.bank_code === '237' ? (
                                //  banco bradesco
                                <Bradesco listBeneficiary={beneficiarys} formDataEdit={formValues} combineFormValues={combineFormValues} />

                            ) : formValues.bank_code === '341' ? (
                                //  itau 
                                <Itau listBeneficiary={beneficiarys} formDataEdit={formValues} combineFormValues={combineFormValues} errors={errors} />
                            ) : formValues.bank_code === '033' ? (
                                //  Santander 
                                <Santander listBeneficiary={beneficiarys} formDataEdit={formValues} combineFormValues={combineFormValues} errors={errors} />
                            )
                                : formValues.bank_code === 'API-BC' ? (
                                    // API-BC (boleto cloud)
                                    <ApiBoletoCloud listBeneficiary={beneficiarys} formDataEdit={formValues} combineFormValues={combineFormValues} />
                                ) : (<></>)
                        }


                    </div>
                </div>
                {/* configurações */}

                {
                    exibirConfiguracao === true && (

                        <div className="col-md-6 mt-3">
                            <div className='mb-10 row'>

                                <h5 className="fs-6">CONFIGURAÇÕES PADRÃO</h5>

                                <div className='col-md-6'>
                                    <div className="form-floating mb-7">

                                        <NumericFormat
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix=""
                                            suffix=" %"
                                            thousandsGroupStyle="thousand"
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            allowLeadingZeros={true}
                                            className={`form-control`}
                                            name="fine_rate"
                                            value={formValues.fine_rate || '0,00'}
                                            onValueChange={(values) => {
                                                handleInputChange(values.floatValue || 0, "fine_rate");
                                            }
                                            }
                                        />
                                        <label htmlFor="fine_rate">Taxa multa</label>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className="form-floating mb-7">

                                        <NumericFormat
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix=""
                                            suffix=" %"
                                            thousandsGroupStyle="thousand"
                                            fixedDecimalScale={true}
                                            decimalScale={5}
                                            allowLeadingZeros={true}
                                            className={`form-control`}
                                            name="interest_rate"
                                            value={formValues.interest_rate || '0,00'}
                                            onValueChange={(values) => {
                                                handleInputChange(values.floatValue || 0, "interest_rate");
                                            }
                                            }
                                        />
                                        <label htmlFor="interest_rate">Taxa juros</label>
                                    </div>
                                </div>

                                <div className="col-md-6">

                                    <label htmlFor="">Protestar</label>
                                    <div className="form-floating mb-7">
                                        <Select
                                            styles={{ container: (base: any) => ({ ...base, zIndex: 10 }) }}
                                            className="w-100"
                                            placeholder=""
                                            value={
                                                !!formValues.protestar
                                                    ? optionsProtestarBanks.find((option: Option) => option.value === formValues.protestar)
                                                    : { value: '', label: 'Selecione' }
                                            }
                                            onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "protestar")}
                                            options={optionsProtestarBanks}
                                        />

                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className="form-floating mb-7">
                                        <input className="form-control"
                                            placeholder="Prazo para Protesto"
                                            name="prazo_protesto"
                                            id="prazo_protesto"
                                            value={formValues.prazo_protesto || ''}
                                            onChange={(e: any) => {
                                                handleInputChange(e.target.value || '', "prazo_protesto");
                                            }
                                            }
                                        />
                                        <label htmlFor="prazo_protesto">Prazo para Protesto</label>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className="form-floating mb-7">
                                        <input className="form-control"
                                            placeholder="Dias para Desconto"
                                            name="days_discount"
                                            id="days_discount"
                                            value={formValues.days_discount || ''}
                                            onChange={(e: any) => {
                                                handleInputChange(e.target.value || '', "days_discount");
                                            }
                                            }
                                        />
                                        <label htmlFor="days_discount">Dias para Desconto</label>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className="form-floating mb-7">

                                        <NumericFormat
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            prefix="R$ "
                                            suffix=""
                                            thousandsGroupStyle="thousand"
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            allowLeadingZeros={true}
                                            className={`form-control`}
                                            name="discount"
                                            value={formValues.discount || '0,00'}
                                            onValueChange={(values) => {
                                                handleInputChange(values.floatValue || 0, "discount");
                                            }
                                            }
                                        />
                                        <label htmlFor="interest_rate">Desconto</label>
                                    </div>
                                </div>


                                <div className="col-md-6">

                                    <label htmlFor="">Baixar</label>
                                    <div className="form-floating mb-7">
                                        <Select
                                            styles={{ container: (base: any) => ({ ...base, zIndex: 10 }) }}
                                            className="w-100"
                                            placeholder=""
                                            value={
                                                !!formValues.baixar
                                                    ? optionsBaixarBanks.find((option: Option) => option.value === formValues.baixar)
                                                    : { value: '', label: 'Selecione' }
                                            }
                                            onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "baixar")}
                                            options={optionsBaixarBanks}
                                        />

                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className="form-floating mb-7">
                                        <input className="form-control"
                                            placeholder="Prazo baixar"
                                            name="prazo_baixar"
                                            id="prazo_baixar"
                                            value={formValues.prazo_baixar || ''}
                                            onChange={(e: any) => {
                                                handleInputChange(e.target.value || '', "prazo_baixar");
                                            }
                                            }
                                        />
                                        <label htmlFor="prazo_baixar">Prazo baixar</label>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className="form-floating mb-7">
                                        <input className="form-control"
                                            placeholder="Dias multa"
                                            name="days_multa"
                                            id="days_multa"
                                            value={formValues.days_multa || ''}
                                            onChange={(e: any) => {
                                                handleInputChange(e.target.value || '', "days_multa");
                                            }
                                            }
                                        />
                                        <label htmlFor="days_multa">Dias multa</label>
                                    </div>
                                </div>

                                <div className="col-md-6">

                                    <label htmlFor="">Emissao boleto</label>
                                    <div className="form-floating mb-7">
                                        <Select
                                            styles={{ container: (base: any) => ({ ...base, zIndex: 10 }) }}
                                            className="w-100"
                                            placeholder=""
                                            value={
                                                !!formValues.emissao_boleto
                                                    ? optionsEmissaoBoleto.find((option: Option) => option.value === formValues.emissao_boleto)
                                                    : { value: '', label: 'Selecione' }
                                            }
                                            onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "emissao_boleto")}
                                            options={optionsEmissaoBoleto}
                                        />

                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className="form-floating mb-7">
                                        <input className="form-control"
                                            placeholder="Layout"
                                            name="layout"
                                            id="layout"
                                            value={formValues.layout || 'cnab400'}
                                            onChange={(e: any) => {
                                                handleInputChange(e.target.value || 'cnab400', "layout");
                                            }
                                            }
                                        />
                                        <label htmlFor="layout">Layout</label>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className="form-floating mb-7">
                                        <input className="form-control"
                                            placeholder="Situação do arquivo"
                                            name="situation_file"
                                            id="situation_file"
                                            value={formValues.situation_file || 'T'}
                                            onChange={(e: any) => {
                                                handleInputChange(e.target.value || '', "situation_file");
                                            }
                                            }
                                        />
                                        <label htmlFor="situation_file">Situação do arquivo</label>
                                    </div>
                                </div>

                                <div className="col-md-12">

                                    <label htmlFor="">Tipo do título</label>
                                    <div className="form-floating mb-7">
                                        <Select
                                            styles={{ container: (base: any) => ({ ...base, zIndex: 10 }) }}
                                            className="w-100"
                                            placeholder=""
                                            value={
                                                !!formValues.type_title
                                                    ? optionsTitulosBanks.find((option: Option) => option.value === formValues.type_title)
                                                    : { value: '', label: 'Selecione' }
                                            }
                                            onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "type_title")}
                                            options={optionsTitulosBanks}
                                        />

                                    </div>
                                </div>

                            </div>
                        </div>
                    )
                }
            </div>
            <div className="card-footer pt-4" >
                <div className='d-flex justify-content-center'>

                    <button type='button' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'
                        onClick={() => confirmSaveForm()}
                    >
                        Salvar
                    </button>
                </div>
            </div>
        </form>
    );
}