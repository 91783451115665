import React, { useContext, useEffect, useState } from 'react';
import DateRangePickerComponent from '../../../layout/components/dates/DateRangePickerComponent';
import Select from "react-select";
// import { FormContext } from '../../../context/dashboard/ContextFormFilter';
import FormData from 'form-data';
import moment, { Moment } from "moment";
import "moment/locale/pt-br";


import { useDispatch } from 'react-redux';
import { setFilter } from '../../../../app/redux/filter/filterSlice';
import { TagifyCustom } from '../../../layout/components/buttons/TagifyCustom';
import { useFetchListProductQuery } from '../../../../app/redux/api/product/listProduct';
import { IFilterParcel } from '../../../../app/interface/I_Parcel';

import { Option } from '../../../../app/interface/I_General';
import { optionsPossuiNotaFiscal, optionsStatusParcel, optionsTypeParcelCliente, optionsTypeParcelHouse, optionsTypePayment } from '../../../../app/globals/OptionsGlobals';
import { KTSVG } from '../../../helpers';
import { useAuth } from '../../../../app/modules/auth';

const FormFilterLisParcel: React.FC = () => {

    const dispatch = useDispatch();

    const [resetDueDate, setResetDueDate] = useState<boolean>(false);
    const [resetPaymentDate, setResetPaymentDate] = useState<boolean>(false);
    const { data: products, error, isLoading } = useFetchListProductQuery(null);
    const [optionsProduct, setOptionsProduct] = useState<Option[]>([]);
    const [formData, setFormData] = useState({
        id_product: '',
        client: '',
        due_date: '',
        status: '',

        start_date: '',
        end_date: '',
        payment_date: {

            start_date: '',
            end_date: '',
        },
        type_parcel: '',
        type_payment: '',
        nota_fiscal: ''
    });

    // const [selectedProduto, setSelectedProduto] = useState('');
    const [nameClient, setNameClient] = useState('');

    const fetchProducts = () => {

        if (products && Array.isArray(products)) {
            const newOptionsProduct = products.map((product: { id: any; name: any; }) => ({
                value: product.id.toString(),
                label: product.name,
            }));

            setOptionsProduct(newOptionsProduct);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [products]);


    const [dueDate, setDueDate] = useState<{ start_date: Moment, end_date: Moment }>({
        start_date: moment().tz('America/Sao_Paulo'),
        end_date: moment().tz('America/Sao_Paulo'),
    });

    function handleDateRangeDueDate(event: Event, picker: any) {
        // const start_date = picker.start_date.tz('America/Sao_Paulo').toDate();
        const start_date = picker.startDate;
        const end_date = picker.endDate;

        setFormData(prevState => ({
            ...prevState,
            start_date: start_date.format('YYYY-MM-DD'),
            end_date: end_date.format('YYYY-MM-DD')

        }));

    }

    function handleDateRangePaymentDate(event: Event, picker: any) {
        // const start_date = picker.start_date.tz('America/Sao_Paulo').toDate();
        const start_date = picker.startDate;
        const end_date = picker.endDate;

        setFormData(prevState => ({
            ...prevState,
            payment_date: {
                ...prevState.payment_date,
                start_date: start_date.format('YYYY-MM-DD'),
                end_date: end_date.format('YYYY-MM-DD')
            }
        }));
    }

    const onSelectTagify = (e: any) => {
        setFormData(prevState => ({
            ...prevState,
            ['client']: e.target.innerText,
        }));
    }

    const handleSelectChange = <K extends keyof IFilterParcel>(selectedOption: Option | null, fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "";

        setFormData(prevState => ({
            ...prevState,
            [fieldName]: value,
        }));

    };


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        // const formDataReact = new FormData();

        // Despachar a ação setFilter com os dados do filtro
        dispatch(setFilter(formData));

    }

    const handleResetComplete = () => {
        setResetDueDate(false);
        setResetPaymentDate(false);
    };

    const resetFilterForm = () => {

        setResetDueDate(true);
        setResetPaymentDate(true);

        // Despachar a ação setFilter com os dados do filtro
        dispatch(setFilter({
            id_product: '',
            client: '',
            due_date: '',
            status: '',
            start_date: '',
            end_date: '',
            type_parcel: '',
            type_payment: '',
            nota_fiscal: '',
        }));
    }

    const { auth } = useAuth();

    let type_parcel = optionsTypeParcelHouse.find(option => option.value === formData.type_parcel)?.label || '';
    let optionsTypeParcel = optionsTypeParcelHouse;
    if (auth?.empresa?.type_assinante === "CLIENT") {
        type_parcel = optionsTypeParcelCliente.find(option => option.value === formData.type_parcel)?.label || '';
        optionsTypeParcel = optionsTypeParcelCliente;
    }

    return (

        <form id="form_filter_contract" onSubmit={handleSubmit}>
            <div className="d-flex flex-column align-items align-items-start">
                <div className="d-flex align-items-center mb-2"></div>
                <div className='mb-10 row'>
                    {/* <div className="col-md-6">
                            <label className='form-label fw-bold'>Produto</label>
                            <div className='d-flex'>
                                <Select
                                    styles={{ container: (base:any) => ({ ...base, zIndex: 99 }) }}
                                    className="w-450px"
                                    placeholder="Selecione um produto"
                                    value={formData.id_product !== '0' && formData.id_product !== '' ? { value: formData.id_product, label: optionsProduct.find(option => option.value === formData.id_product)?.label } : null}
                                    onChange={(selectedOption:any) => handleSelectChange(selectedOption as Option | null, "id_product")}
                                    options={optionsProduct}
                                />
                            </div>
                        </div> */}
                    <div className="col-md-12">
                        <label className='form-label fw-bold'>Cliente</label>
                        <div className=''>
                            <TagifyCustom
                                onSelectTagify={onSelectTagify}
                                selectedId=""
                                selectedName=""
                                endPoint="client"
                            />
                        </div>
                    </div>



                    <div className="mb-4"></div>

                    <div className="col-md-6">
                        <label className='form-label fw-bold'>Forma de Pagamento</label>
                        <div className='d-flex'>
                            <Select
                                styles={{ container: (base: any) => ({ ...base, zIndex: 99 }) }}
                                className="w-450px"
                                placeholder="Selecione"
                                value={formData.type_payment !== '0' && formData.type_payment !== '' ? { value: formData.type_payment, label: optionsTypePayment.find(option => option.value === formData.type_payment)?.label } : null}
                                onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "type_payment")}
                                options={optionsTypePayment}
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <label className='form-label fw-bold'>Status</label>
                        <div className='d-flex'>
                            <Select
                                styles={{ container: (base: any) => ({ ...base, zIndex: 11 }) }}
                                className="w-450px"
                                placeholder="Selecione"
                                value={formData.status !== '0' && formData.status !== '' ? { value: formData.status, label: optionsStatusParcel.find(option => option.value === formData.status)?.label } : null}
                                onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "status")}
                                options={optionsStatusParcel}
                            />
                        </div>
                    </div>

                    <div className="mb-4"></div>

                    <div className="col-md-6">
                        <label className='form-label fw-bold'>Tipo de parcela</label>
                        <div className='d-flex'>
                            <Select
                                styles={{ container: (base: any) => ({ ...base, zIndex: 10 }) }}
                                className="w-450px"
                                placeholder="Selecione"
                                value={formData.type_parcel !== '0' && formData.type_parcel !== '' ?
                                    {
                                        value: formData.type_parcel, label: type_parcel
                                    } : null
                                }
                                onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "type_parcel")}
                                options={optionsTypeParcel}
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <label className='form-label fw-bold'>Possui nota?</label>
                        <div className='d-flex'>
                            <Select
                                styles={{ container: (base: any) => ({ ...base, zIndex: 10 }) }}
                                className="w-450px"
                                placeholder="Selecione"
                                value={{ value: formData.nota_fiscal, label: optionsPossuiNotaFiscal.find(option => option.value === formData.nota_fiscal)?.label }}
                                onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "nota_fiscal")}
                                options={optionsPossuiNotaFiscal}
                            />
                        </div>
                    </div>

                    <div className="mb-4"></div>

                    <div className='col-md-6'>
                        <label className='form-label fw-bold'>Data de pagamento</label>
                        <DateRangePickerComponent
                            onDateRangeSelection={handleDateRangePaymentDate}
                            className="w-100"
                            resetDate={resetDueDate}
                            onResetComplete={handleResetComplete}
                        />

                    </div>

                    <div className='col-md-6'>
                        <label className='form-label fw-bold'>Vencimento</label>
                        <DateRangePickerComponent
                            onDateRangeSelection={handleDateRangeDueDate}
                            className="w-100"
                            resetDate={resetDueDate}
                            onResetComplete={handleResetComplete}
                        />

                    </div>


                </div>
            </div>
            <div className="card-footer pt-4" id="kt_drawer_filter_footer">
                <div className='d-flex justify-content-center'>
                    <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2 btn_reset_filter_all'
                        data-kt-menu-dismiss='true'
                        onClick={() => resetFilterForm()}
                    >
                        Resetar filtro
                    </button>

                    <button type="submit" data-kt-indicator='off' className="btn btn-sm btn-primary me-3 btn_filter_all" data-kt-menu-dismiss='true' data-kt-stepper-action="submit">
                        <span className="indicator-label">
                            Filtrar
                            <KTSVG className="svg-icon svg-icon-2" path="/media/icons/duotune/general/gen031.svg" />
                        </span>
                        <span className="indicator-progress">
                            Carregando... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    </button>
                </div>
            </div>
        </form>
    );
};

export default FormFilterLisParcel;
