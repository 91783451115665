import React, { useEffect, useState } from "react";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import Select from "react-select";
import { useAuth } from "../../modules/auth";
import { getBeneficiary } from "../../request/configurations/_request_banks";
import { IBank, IBeneficiary } from "../../interface/I_Configurations";
import { IFormContract } from '../../interface/I_Contract'
import { getTypeInstallment } from '../../request/contract'
import { downloadBolecodeItau, getBoleCodeItau, getBoleCodeItauPaginate } from '../../request/parcel'
import { optionsTypeRelatorioBolecode } from '../../globals/OptionsGlobals'
import { KTSVG } from '../../../_metronic/helpers'
import { useSelector } from "react-redux";
import { formatCnpjCpf, formatDocumento } from "../../../_metronic/helpers/functions/TestCpf";
import { formatarDataPtBr } from "../../../_metronic/helpers/functions/dates/date";
import { formatarMoedaReal } from "../../../_metronic/helpers/functions/numbers";
import Swal from "sweetalert2";

const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Configuração',
        path: 'configuartions/home',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

interface Option {
    value: string;
    label: string;
}

interface IOptionSelected {
    type_relatorio: string;
}


const BolecodePixItauWrapper = () => {
    const [optionsProduct, setOptionsProduct] = useState<Option[]>([]);
    const [beneficiarys, setBeneficiarys] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(null);
    const filterGlobalProduct = useSelector((state: any) => state.productSlice);

    const [loadDownload, setLoadingDownload] = useState(false);

    const { auth } = useAuth();
    const [formValues, setFormValues] = useState({
        id_product: filterGlobalProduct.product ? filterGlobalProduct.product.id_product : '',
        id_bank: '',
        our_number: '',
        type_relatorio: 'boletos'
    });

    useEffect(() => {
        setFormValues(prevState => ({
            ...prevState,
            id_product: filterGlobalProduct.product ? filterGlobalProduct.product.id_product : ''
        }));
    }, [filterGlobalProduct]);

    const [bolecodeItau, setBolecodeItau] = useState<any>([]);

    useEffect(() => {
        loadBanks();
    }, [])

    const loadBanks = async () => {

        if (auth && auth.api_token) {
            try {
                const response = await getBeneficiary(auth);
                if (response.data && response.data && Array.isArray(response.data)) {

                    const newListBeneficiary = response.data.map((beneficiary: { id: any; registration_type: any; cpf: string; cnpj: string; name: string; }) => ({
                        value: beneficiary.id,
                        label: beneficiary.registration_type === 'cpf' ? `${beneficiary.cpf} - ${beneficiary.name}` : `${beneficiary.cnpj} - ${beneficiary.name}`,
                    }));

                    setBeneficiarys(newListBeneficiary);
                }
            } catch (error) {
                console.error('Erro ao carregar bancos:', error);
            }

        }
    }

    const handleInputChange = (
        value: string | number,
        fieldName: string,
    ) => {

        setFormValues(prevState => ({
            ...prevState,
            [fieldName]: value
        }));

    };

    const handleSelectChange = <K extends keyof IBank>(selectedOption: Option | null, fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "";
        const label = selectedOption ? selectedOption.label : "";
        if (fieldName === 'beneficiary') {
            setFormValues({
                ...formValues,
                [fieldName]: label,
                ['id_bank']: value
            });

        }

    };

    const handleSelectTypeRelatorio = <K extends keyof IOptionSelected>(selectedOption: Option | null, fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "";
        const label = selectedOption ? selectedOption.label : "";
        setFormValues({
            ...formValues,
            [fieldName]: value,
        });

        setBolecodeItau([])

    };

    const fetchData = async () => {
        try {

            const response = await getBoleCodeItau(formValues);

            if (response) {
                setBolecodeItau([]);
                if (formValues.type_relatorio === 'boletos') {
                    setBolecodeItau(response.data.boletos);
                    setCurrentPage((response.data.boletos.page.page + 1));
                } else {

                    if (response.data.boletos.hasOwnProperty('status')) {
                        if (response.data.boletos.status != 200) {
                            Swal.fire({
                                title: "Atenção",
                                html: `${response.data.boletos.violacoes[0].razao}. <br/> Campo 'Nosso múmero' está vazio`,
                                icon: "warning"
                            });
                        } else {
                            setBolecodeItau(response.data.boletos);
                        }
                    } else {
                        setBolecodeItau(response.data.boletos);
                    }
                }
            }
        } catch (error) {
            // Trate o erro aqui
            console.log("Ocorreu um erro ao buscar os dados de boletos do Itaú:", error);
        }
    }

    const getBoleCodePix = () => {
        fetchData();
    }

    const fetchDownloadBolecode = async () => {
        try {
            const combinedData = {
                ...bolecodeItau,
                formValues: formValues
            };
            const response = await downloadBolecodeItau(combinedData);

            if (response) {
                if (response.data.hasOwnProperty('status')) {
                    if (response.data.status === 200) {
                        setLoadingDownload(false);

                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = `data:application/octet-stream;base64,${response.data.excel}`;
                        a.download = response.data.name_excel;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    } else {
                        setLoadingDownload(false);
                        Swal.fire({
                            title: "Atenção",
                            html: `Não foi possível realizar o seu downoad tente novamente`,
                            icon: "warning"
                        });
                    }
                }
            }
        } catch (error) {
            // Trate o erro aqui

            setLoadingDownload(false);
            console.log("Ocorreu um erro ao buscar os dados de boletos do Itaú:", error);
        }
    }

    const downloadBolecode = () => {

        setLoadingDownload(true);
        fetchDownloadBolecode();
    }

    const Pagination = ({ paginate }: { paginate: any }) => {
        // Extrair os links de primeira, anterior, próxima e última página

        // console.log('paginaete ',paginate,  paginate.links)
        const { first, previous, next, last } = paginate.links
        // console.log('first, previous, next, last ', first, previous, next, last)

        let disabledFirst = paginate.page === 0 ? true : false;
        let disabledPrevious = paginate.page === 0 ? true : false;

        let disabledNext = (paginate.page >= 0) && (paginate.page < paginate.total_pages) ? false : true;
        let disabledLast = paginate.page === paginate.total_pages ? true : false;

        const handlePageClick = async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: string) => {

            event.preventDefault();

            let form_data = {
                id_bank: formValues.id_bank,
                paginate: link,
            }
            const response = await getBoleCodeItauPaginate(form_data);

            if (response) {
                setBolecodeItau([]);
                if (formValues.type_relatorio === 'boletos') {
                    console.log('reposnse ', response.data.boletos.page)
                    setBolecodeItau(response.data.boletos);
                    setCurrentPage((response.data.boletos.page.page + 1));
                } else {
                    setBolecodeItau(response.data.boletos);
                }
            }
        };

        return (
            <ul className="pagination">
                <li className={`page-item ${disabledFirst ? 'disabled' : ''}`}>
                    <a href="#" className="page-link" onClick={(e) => handlePageClick(e, first)}>
                        <KTSVG
                            path='/media/icons/duotune/arrows/arr079.svg'
                            className='svg-icon-6 svg-icon-muted me-1'
                        />
                    </a>
                </li>
                <li className={`page-item ${disabledPrevious ? 'disabled' : ''}`}>
                    <a href="#" className="page-link" onClick={(e) => handlePageClick(e, previous)}>
                        <KTSVG
                            path='/media/icons/duotune/arrows/arr074.svg'
                            className='svg-icon-6 svg-icon-muted me-1'
                        />
                    </a>
                </li>
                <li className={`page-item ${disabledNext ? 'disabled' : ''}`}>
                    <a href="#" className="page-link" onClick={(e) => handlePageClick(e, next)}>
                        <KTSVG
                            path='/media/icons/duotune/arrows/arr071.svg'
                            className='svg-icon-6 svg-icon-muted me-1'
                        />
                    </a>
                </li>

                <li className={`page-item ${disabledLast ? 'disabled' : ''}`}>
                    <a href="#" className="page-link" onClick={(e) => handlePageClick(e, last)}>
                        <KTSVG
                            path='/media/icons/duotune/arrows/arr080.svg'
                            className='svg-icon-6 svg-icon-muted me-1'
                        />
                    </a>
                </li>
            </ul>
        );
    };

    return (
        <>
            <div className="container-xxl ">
                <div className="d-flex flex-column flex-lg-row">
                    <div className="flex-lg-row-fluid order-2 order-lg-1 mb-10 mb-lg-0">
                        <PageTitle breadcrumbs={profileBreadCrumbs} >Status das parcelas</PageTitle>
                        <div className="card card-flush pt-3 mb-5 mb-xl-10">
                            <div className="card-header  position-relative">

                                <div className="card-title">
                                    <h2 className="fw-bold">Detalhe dos boletos do Itaú</h2>
                                </div>
                                <div className="mt-7 d-flex align-items-center gap-3 gap-lg-2">


                                </div>
                            </div>
                            <div className="card-body pt-3">
                                <div className="mb-10">
                                    <div className="row flex-wrap py-5">
                                        <div className="flex-equal me-5">
                                            <div className="row gy-5">
                                                <div className="col-lg-2  fv-plugins-icon-container">
                                                    <label className='form-label fw-bold'>Tipo de relatório</label>
                                                    <div className=''>
                                                        <Select
                                                            name="type_relatorio"
                                                            className="w-auto"
                                                            placeholder="Tipo de relatório"
                                                            value={
                                                                !!formValues.type_relatorio
                                                                    ? optionsTypeRelatorioBolecode.find((option: any) => option.value === formValues.type_relatorio)
                                                                    : { value: '', label: '' }
                                                            }
                                                            onChange={(selectedOption: any) => handleSelectTypeRelatorio(selectedOption as Option | null, "type_relatorio")}
                                                            options={optionsTypeRelatorioBolecode}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-5  fv-plugins-icon-container">
                                                    <label className='form-label fw-bold'>Conta </label>&nbsp;&nbsp;
                                                    <span className={'text-danger'}>* campo obrigatório</span>
                                                    <div className=''>
                                                        <Select
                                                            name="id_bank"
                                                            className="w-auto"
                                                            placeholder="Selecione um Produto"
                                                            value={
                                                                !!formValues.id_bank
                                                                    ? beneficiarys.find((option: IBeneficiary) => option.value === formValues.id_bank)
                                                                    : { value: '', label: '' }
                                                            }
                                                            onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "beneficiary")}
                                                            options={beneficiarys}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3  fv-plugins-icon-container">
                                                    <label className='form-label fw-bold'>Nosso número</label>
                                                    <div className=''>
                                                        <input
                                                            className={`form-control`}
                                                            name="our_number"
                                                            value={formValues.our_number || ''}
                                                            onChange={(e) => {
                                                                handleInputChange(e.target.value, "our_number")
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-2">
                                                    <label className=''>&nbsp;</label>
                                                    <div className='d-flex'>
                                                        <a
                                                            href='#'
                                                            className={`btn btn-sm btn-flex fw-bold btn-primary mt-3`}
                                                            data-kt-menu-trigger='click'
                                                            id='search_pix'
                                                            data-kt-menu-placement='bottom-end'
                                                            onClick={getBoleCodePix}
                                                        >
                                                            <KTSVG
                                                                path='/media/icons/duotune/general/gen031.svg'
                                                                className='svg-icon-6 svg-icon-muted me-1'
                                                            />
                                                            Buscar
                                                        </a>
                                                        {
                                                            formValues.type_relatorio === 'boletos' && bolecodeItau && Array.isArray(bolecodeItau.data) && bolecodeItau.data.length > 0
                                                                ? (
                                                                    <button type="button"
                                                                        disabled={loadDownload}
                                                                        data-kt-indicator={`${loadDownload ? 'on' : 'off'}`}
                                                                        className={`btn btn-sm btn-flex fw-bold btn-primary mt-3 ms-3`}
                                                                        onClick={downloadBolecode}
                                                                    >
                                                                        <KTSVG
                                                                            path='/media/icons/duotune/files/fil021.svg'
                                                                            className='svg-icon-6 svg-icon-muted me-1'
                                                                        />
                                                                        {
                                                                            loadDownload && (
                                                                                <span className="indicator-progress">
                                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                                </span>
                                                                            )
                                                                        }

                                                                    </button>
                                                                )
                                                                : (<></>)
                                                        }

                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                        {
                                            formValues.type_relatorio === 'boletos'
                                                ? (
                                                    bolecodeItau && Array.isArray(bolecodeItau.data) && bolecodeItau.data.length > 0
                                                        ? (
                                                            <>
                                                                {
                                                                    bolecodeItau.data.map((value: any, index: number) => {
                                                                        //    const _divisor = index !== contractTypeInstallment.data - 1 ? <><div className="separator my-2"></div></> : '';
                                                                        const _divisor = <>
                                                                            <div className="separator my-2"></div>
                                                                        </>;

                                                                        let status_vencimento = value.dado_boleto.dados_individuais_boleto[0]['status_vencimento'];
                                                                        let situacao_geral_boleto = value.dado_boleto.dados_individuais_boleto[0]['situacao_geral_boleto'];
                                                                        let valor_titulo = value.dado_boleto.dados_individuais_boleto[0]['valor_titulo'];
                                                                        let data_vencimento = value.dado_boleto.dados_individuais_boleto[0]['data_vencimento'];
                                                                        let texto_seu_numero = value.dado_boleto.dados_individuais_boleto[0]['texto_seu_numero'];
                                                                        let nosso_numero = value.dado_boleto.dados_individuais_boleto[0]['numero_nosso_numero'];

                                                                        let content_baixa: string | JSX.Element = '';
                                                                        let data_baixa = '';
                                                                        let motivo_baixa = '';

                                                                        if (value.dado_boleto.hasOwnProperty('baixa')) {
                                                                            data_baixa = value.dado_boleto.baixa.data_inclusao_alteracao_baixa;
                                                                            motivo_baixa = value.dado_boleto.baixa.motivo_baixa;

                                                                            content_baixa = (<div
                                                                                className="d-flex justify-content-start flex-column">
                                                                                <span
                                                                                    className="text-gray-800 fw-bold text-hover-primary mb-1 fs-8">Data da baixa: {formatarDataPtBr(data_baixa)}</span>
                                                                                <span
                                                                                    className="text-gray-500 fw-semibold d-block fs-7">Motivo: {motivo_baixa}</span>
                                                                            </div>)
                                                                        }

                                                                        let protesto: string | JSX.Element = '';

                                                                        if (value.dado_boleto.hasOwnProperty('protesto')) {
                                                                            if (value.dado_boleto.protesto.hasOwnProperty('codigo_tipo_protesto')) {
                                                                                if (value.dado_boleto.protesto.hasOwnProperty('descricao')) {
                                                                                    protesto = (
                                                                                        <div className="badge badge-light-danger ms-3 mt-1 mb-1">
                                                                                            <span className="fs-8">{value.dado_boleto.protesto.descricao} - dias: {value.dado_boleto.protesto.quantidade_dias_protesto} </span>
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                            }

                                                                        }

                                                                        let negativacao: string | JSX.Element = '';

                                                                        if (value.dado_boleto.hasOwnProperty('negativacao')) {
                                                                            if (value.dado_boleto.negativacao.hasOwnProperty('codigo_situacao_negativacao')) {
                                                                                negativacao = (
                                                                                    <div className="badge badge-light-danger ms-3 mt-1 mb-1">
                                                                                        <span className="fs-8">{value.dado_boleto.negativacao.descricao}</span>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                        }

                                                                        let pagador = value.dado_boleto.pagador.pessoa.nome_pessoa;
                                                                        let codigo_tipo_pessoa = 'Fisica';
                                                                        let tipo_pessoa = 'CPF';
                                                                        if (value.dado_boleto.pagador.pessoa.tipo_pessoa.codigo_tipo_pessoa != 'F') {
                                                                            codigo_tipo_pessoa = 'Juridica';
                                                                            tipo_pessoa = 'CNPJ';
                                                                        }

                                                                        let numero_cadastro_pessoa_fisica: number | string = value.dado_boleto.pagador.pessoa.tipo_pessoa.numero_cadastro_pessoa_fisica;
                                                                        let cadastro_pessoa_fisica: string = formatDocumento(numero_cadastro_pessoa_fisica);
                                                                        let nome_logradouro = value.dado_boleto.pagador.endereco.nome_logradouro;
                                                                        let nome_bairro = value.dado_boleto.pagador.endereco.nome_bairro;
                                                                        let nome_cidade = value.dado_boleto.pagador.endereco.nome_cidade;
                                                                        let sigla_UF = value.dado_boleto.pagador.endereco.sigla_UF;
                                                                        let numero_CEP = value.dado_boleto.pagador.endereco.numero_CEP;


                                                                        {
                                                                            return (
                                                                                <>
                                                                                    <div
                                                                                        key={`bolecode_${value.id_boleto}-${index}`}
                                                                                        id={`details_bolecode_${value.id_boleto}-${index}`}
                                                                                        className="card-body mb-n7 pt-0">

                                                                                        <div className="py-0" data-kt-customer-payment-method="row">
                                                                                            <div className="py-3 d-flex flex-stack flex-wrap">
                                                                                                <a className="d-flex align-items-center collapsible rotate collapsed"
                                                                                                    data-bs-toggle="collapse"
                                                                                                    href={`#bolecode_ref_${value.id_boleto}-${index}`}
                                                                                                    role="button"
                                                                                                    aria-expanded="false"
                                                                                                    aria-controls={`bolecode_ref_${value.id_boleto}-${index}`}>
                                                                                                    <div
                                                                                                        className="me-3 rotate-90">
                                                                                                        <KTSVG
                                                                                                            path={"/media/icons/duotune/arrows/arr071.svg"} />
                                                                                                    </div>

                                                                                                    {/* <img src="/metronic8/demo1/assets/media/svg/card-logos/mastercard.svg" className="w-40px me-3" alt="" /> */}

                                                                                                    <div
                                                                                                        className="me-3">
                                                                                                        <div
                                                                                                            className="d-flex align-items-center">
                                                                                                            <div
                                                                                                                className="text-gray-800 fw-bold">
                                                                                                                {pagador} - {codigo_tipo_pessoa}
                                                                                                            </div>

                                                                                                            <div
                                                                                                                className="badge badge-light-primary ms-5">
                                                                                                                {`${situacao_geral_boleto} - ${status_vencimento}`}
                                                                                                            </div>

                                                                                                            <div
                                                                                                                className="badge badge-light-success ms-5">
                                                                                                                {texto_seu_numero}
                                                                                                            </div>

                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="text-muted">Valor
                                                                                                            da
                                                                                                            parcela: {formatarMoedaReal(valor_titulo)} -
                                                                                                            Vencimento: {formatarDataPtBr(data_vencimento)}

                                                                                                            {protesto}
                                                                                                            {negativacao}
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </a>
                                                                                                &nbsp;&nbsp;
                                                                                                <div className="text-muted justify-content-start mt-n5">
                                                                                                    Nosso número: <span className={''}>{nosso_numero}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="d-flex my-3 ms-9">


                                                                                                    {content_baixa}

                                                                                                </div>

                                                                                            </div>

                                                                                            <div
                                                                                                id={`bolecode_ref_${value.id_boleto}-${index}`}
                                                                                                className="fs-6 ps-10 collapse"
                                                                                                data-bs-parent={`#details_bolecode_${value.id_boleto}-${index}`}>
                                                                                                <div
                                                                                                    className="d-flex flex-wrap py-5">
                                                                                                    <div
                                                                                                        className="flex-equal me-5">
                                                                                                        <table
                                                                                                            className="table table-flush fw-semibold gy-1">
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td className="min-w-200px w-250px">
                                                                                                                        <label
                                                                                                                            className="w-250px">Logradouro</label>
                                                                                                                        <div
                                                                                                                            className="fw-normal text-gray-600">
                                                                                                                            {nome_logradouro}
                                                                                                                        </div>
                                                                                                                    </td>

                                                                                                                    <td>
                                                                                                                        <label
                                                                                                                            className="w-150px">Bairro</label>
                                                                                                                        {/* <div className="fw-normal text-gray-600">{value.value}</div> */}
                                                                                                                        <div
                                                                                                                            className="fw-normal text-gray-600">{nome_bairro}</div>
                                                                                                                    </td>

                                                                                                                    <td>
                                                                                                                        <label
                                                                                                                            className="w-150px">Cidade</label>
                                                                                                                        <div
                                                                                                                            className="fw-normal text-gray-600">{nome_cidade}</div>
                                                                                                                    </td>

                                                                                                                    <td>
                                                                                                                        <label
                                                                                                                            className="w-150px">Estado</label>
                                                                                                                        <div
                                                                                                                            className="fw-normal text-gray-600">{sigla_UF}</div>
                                                                                                                    </td>


                                                                                                                </tr>
                                                                                                                <tr>

                                                                                                                    <td>
                                                                                                                        <label
                                                                                                                            className="w-150px">Cep</label>
                                                                                                                        <div
                                                                                                                            className="fw-normal text-gray-600">
                                                                                                                            {numero_CEP}
                                                                                                                        </div>
                                                                                                                    </td>

                                                                                                                    <td>
                                                                                                                        <label
                                                                                                                            className="w-150px">{tipo_pessoa}</label>
                                                                                                                        <div
                                                                                                                            className="fw-normal text-gray-600">{cadastro_pessoa_fisica}</div>
                                                                                                                    </td>

                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {_divisor}
                                                                                    </div>

                                                                                </>
                                                                            )
                                                                        }
                                                                    })
                                                                }

                                                                <div>
                                                                    <Pagination paginate={bolecodeItau.page} />
                                                                    <p>Página atual {currentPage} de {bolecodeItau.page.total_pages} total de itens: {bolecodeItau.page.total_elements}</p>
                                                                </div>
                                                            </>
                                                        )
                                                        : (<><img src={"/media/illustrations/sketchy-1/5.png"}
                                                            style={{ height: '300px', objectFit: 'scale-down' }} /></>)
                                                ) : (
                                                    <>
                                                        {
                                                            bolecodeItau && Object.keys(bolecodeItau).length > 0 ? (

                                                                <>

                                                                    <div className="separator my-2 mt-5"></div>
                                                                    <div
                                                                        className="py-3 d-flex flex-stack flex-wrap">
                                                                        <div
                                                                            className="d-flex align-items-center collapsible rotate collapsed">
                                                                            <div className="me-3">
                                                                                <div
                                                                                    className="d-flex align-items-center">
                                                                                    <div
                                                                                        className="text-gray-800 fw-bold">
                                                                                        {bolecodeItau.devedor.nome}
                                                                                    </div>

                                                                                </div>

                                                                                CPF/CNPJ:&nbsp;&nbsp;
                                                                                <div
                                                                                    className="badge badge-light-primary">
                                                                                    {`${formatDocumento(bolecodeItau.devedor.cpf)}`}
                                                                                </div>
                                                                                &nbsp;&nbsp;&nbsp;&nbsp;

                                                                                Status:&nbsp;&nbsp;
                                                                                <div
                                                                                    className="badge badge-light-success">
                                                                                    {bolecodeItau.status || ''}
                                                                                </div>
                                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                {/*Documento:&nbsp;&nbsp;*/}
                                                                                {/*<div*/}
                                                                                {/*    className="badge badge-light-warning">*/}
                                                                                {/*    {bolecodeItau.infoAdicionais[2].valor || ''}*/}
                                                                                {/*</div>*/}
                                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                                Nosso número:&nbsp;&nbsp;
                                                                                <div
                                                                                    className="badge badge-light-warning">
                                                                                    {bolecodeItau.infoAdicionais[1].valor || ''}
                                                                                </div>
                                                                                <div
                                                                                    className="text-muted mb-2 mt-2">Valor da
                                                                                    parcela: {formatarMoedaReal(parseFloat(bolecodeItau.valor.original) || 0)}
                                                                                    &nbsp;&nbsp;
                                                                                    Horário do
                                                                                    pix: {formatarDataPtBr(bolecodeItau.calendario.criacao)}
                                                                                </div>
                                                                                Copia e cola: &nbsp;&nbsp;
                                                                                <div
                                                                                    className="border border-gray-300 border-dashed rounded p-2 mb-2 mt-1">
                                                                                    {bolecodeItau.pixCopiaECola}

                                                                                </div>

                                                                                Código de barras: &nbsp;&nbsp;
                                                                                <div className="badge badge-light-info">

                                                                                    {
                                                                                        (() => {

                                                                                            if (bolecodeItau.infoAdicionais.hasOwnProperty([9])) {
                                                                                                return (<>{bolecodeItau.infoAdicionais[9].valor}</>);
                                                                                            }
                                                                                        })()
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="separator my-2 mt-5"></div>
                                                                </>
                                                            ) : (
                                                                <><img src={"/media/illustrations/sketchy-1/5.png"}
                                                                    style={{
                                                                        height: '300px',
                                                                        objectFit: 'scale-down'
                                                                    }} /></>
                                                            )
                                                        }
                                                    </>
                                                )
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}


export default BolecodePixItauWrapper;