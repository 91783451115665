import React, { ReactNode, useEffect, useState } from "react";
import flattenChildren from "react-keyed-flatten-children";
import { permissionsUser } from "../../../../app/request/permission";
import { PermissionsProps, ChildComponentProps } from "../../../../app/interface/I_Permission";
import usePermissionStore from "../../../../app/zustand/PermissionStore";

// Importação dos componentes filhos
import Access from "./Access";
import Create from "./Create";
import Edit from "./Edit";
import Delete from "./Delete";
import List from "./List";
import Import from "./Import";
import Export from "./Export";
import Retorno from "./Retorno";
import Remessa from "./Remessa";
import Spreadsheet from "./Spreadsheet";
import Bolecode from "./Bolecode";
import Approval from "./Approval";
import Dev from "./Dev";

const ComponentPermission: React.FC<PermissionsProps> & {
    Access: React.FC<ChildComponentProps>;
    Create: React.FC<ChildComponentProps>;
    Edit: React.FC<ChildComponentProps>;
    Delete: React.FC<ChildComponentProps>;
    List: React.FC<ChildComponentProps>;
    Import: React.FC<ChildComponentProps>;
    Export: React.FC<ChildComponentProps>;
    Retorno: React.FC<ChildComponentProps>;
    Remessa: React.FC<ChildComponentProps>;
    Spreadsheet: React.FC<ChildComponentProps>;
    Bolecode: React.FC<ChildComponentProps>;
    Approval: React.FC<ChildComponentProps>;
    Dev: React.FC<ChildComponentProps>;
} = ({ children, ...props }) => {
    const { renderType, className, includeVerify } = props;
    const permissionsStore = usePermissionStore();
    const [allowedPermissions, setAllowedPermissions] = useState<string[]>([]);

    useEffect(() => {
        setAllowedPermissions(permissionsStore.permissions);
    }, [permissionsStore.permissions]);

    const hasWildcardMatch = (equalVerify?: string) => {
        return allowedPermissions.some(permission => {
            if (permission.includes('.*')) {
                const [prefix] = permission.split('.*');
                return equalVerify ? equalVerify === prefix : permissionsStore.currentRoute === prefix;
            }
            return false;
        });
    };

    const isAllowed = (require: string, equalVerify?: any) => {
        if (equalVerify) {
            if (hasWildcardMatch(equalVerify)) return true;
            return allowedPermissions.includes(`${equalVerify}.${require}`);
        }

        if (hasWildcardMatch()) return true;

        return allowedPermissions.includes(`${permissionsStore.currentRoute}.${require}`);
    };

    const processChild = (child: ReactNode): ReactNode | null => {
        if (React.isValidElement(child)) {
            const equalVerify = child.props?.equalVerify;
            const childType = child.type as React.ComponentType<any>;

            // Verificação mais segura para tipos de componentes
            if (typeof childType === 'function') {
                const permissionType = childType.name?.toLowerCase();

                if (permissionType && isAllowed(permissionType, equalVerify)) {
                    return React.cloneElement(child, { isallowed: 'true' } as any);
                }
            }
        }
        return null;
    };

    const processChildren = (children: React.ReactNode): React.ReactNode => {
        return React.Children.map(children, processChild);
    };

    const processedChildren = processChildren(children);

    if (renderType) {
        switch (renderType) {
            case "disabled":
                return (
                    <div className={className || ''} style={{ cursor: 'not-allowed' }}>
                        <div style={{ pointerEvents: 'none', opacity: '0.5' }}>
                            {processedChildren}
                        </div>
                    </div>
                );
            case "remove":
                return null;
            default:
                return (
                    <div className={className || ''} style={{ cursor: 'not-allowed' }}>
                        <div style={{ pointerEvents: 'none', opacity: '0.5' }}>
                            {processedChildren}
                        </div>
                    </div>
                );
        }
    } else {
        return <>{processedChildren}</>;
    }
};

ComponentPermission.Access = Access;
ComponentPermission.Create = Create;
ComponentPermission.Edit = Edit;
ComponentPermission.Delete = Delete;
ComponentPermission.List = List;
ComponentPermission.Import = Import;
ComponentPermission.Export = Export;
ComponentPermission.Retorno = Retorno;
ComponentPermission.Remessa = Remessa;
ComponentPermission.Spreadsheet = Spreadsheet;
ComponentPermission.Bolecode = Bolecode;
ComponentPermission.Approval = Approval;
ComponentPermission.Dev = Dev;

export default ComponentPermission;
