/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { CardBudgetVendas } from '../../../_metronic/partials/dashboard/CardBudgetVendas'
import { Alerts } from './Alerts'
import TableDefaulters from './TableDefaulters'
import ReportFrame from '../../../_metronic/partials/dashboard/ReportFrame'
import { getReportFrame } from '../../request/dasboard'

const DashboardPage: FC = () => {

    const [reportFrame, setReportFrame] = useState([]);

    const loadReportFrame = async () => {
        const response = await getReportFrame();
        if (response && !!response.data) {
            setReportFrame(response.data);
        }
    }
    // loadReportFrame()
    useEffect(() => {
        loadReportFrame();

    }, [])

    return (
        <>
            <Alerts />
            <CardBudgetVendas />

            {
                reportFrame && Array.isArray(reportFrame)
                    ? reportFrame.map((report: any, index) => (

                        <ReportFrame props={report} key={index} />
                    ))
                    : (<></>)
            }

        </>
    )
}

const DashboardWrapper: FC = () => {
    const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            <DashboardPage />
        </>
    )
}

export { DashboardWrapper }
