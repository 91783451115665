import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { optionsTypeParcelCliente, optionsTypeParcelHouse } from "../../../app/globals/OptionsGlobals";
import { ITypeInstallment } from "../../../app/interface/I_Contract";
import { IResponse } from "../../../app/interface/I_General";
import { FormValues } from "../../../app/interface/I_Parcel";
import { useAuth } from "../../../app/modules/auth";
import { getTypeInstallment } from "../../../app/request/contract";
import { deleteConditionAPI, editParcelAPI, insertParcelAPI } from "../../../app/request/parcel";
import { KTSVG } from "../../helpers";
import { formatDateDefaultString, formatDateToBrString } from "../../helpers/functions/export/format";
import { LoadAnimation } from "../../helpers/functions/swal/LoadAnimation";
import { FormInsertParcel } from "./modal/parcel/FormInsertParcel";

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});

export const TypeInstallment = ({ id }: { id: number | undefined }) => {

    const [contractTypeInstallment, setcontractTypeInstallment] = useState<any>([]);

    const [isModalConditionPaymentOpen, setIsModalConditionPaymentOpen] = useState(false);
    const [typeSave, setTypeSave] = useState('');
    const { auth, logout, setCurrentUser } = useAuth();

    // seta valores padrões para o formulario
    const [formValues, setFormValues] = useState<FormValues>({
        id: 0,
        number_parcel: '1',
        is_recurring: '0',
        frequency: '1M',
        form_payment: 'Boleto',
        type_parcel: '',
        due_date: '',
        value: '0.0',
        status: 'pendente',
        readjustment_index: 'nenhum',
        interest_rate: '',
        generate_billing_installments: 'sim',
        table: 'payment_conditions'

    });

    // valores padrões para erros
    const [errorsForm, setErrorsForm] = useState<FormValues>({
        id: 0,
        number_parcel: '',
        is_recurring: '',
        frequency: '1M',
        form_payment: '',
        type_parcel: '',
        due_date: '',
        value: '',
        status: '',
        interest_rate: '',
        readjustment_index: 'nenhum',

    });

    /** ao clicar no botão inserir reseta os campos */
    const handleInsertConditionPayment = () => {
        setTypeSave('insert');
        setFormValues({
            id: 0,
            number_parcel: '1',
            is_recurring: '0',
            frequency: '1M',
            form_payment: 'Boleto',
            type_parcel: '',
            due_date: '',
            value: '0.0',
            status: 'pendente',
            readjustment_index: 'nenhum',
            interest_rate: '',
            generate_billing_installments: 'sim',
            table: 'payment_conditions'
        });

        setIsModalConditionPaymentOpen(true);
    };

    /** ao clicar no botão editar */
    const handleEditConditionPayment = (row: any) => {
        setTypeSave('edit');

        setFormValues({
            id: row.id,
            number_parcel: row.number_parcel || '1',
            is_recurring: row.is_recurring || '0',
            frequency: row.frequency || '1M',
            form_payment: row.form_payment || 'Boleto',
            type_parcel: row.type_parcel || '',
            due_date: formatDateDefaultString(row.due_date),
            value: row.value || 0,
            status: row.status || 'pendente',
            readjustment_index: row.readjustment_index || 'nenhum',
            interest_rate: row.interest_rate || 0,
            generate_billing_installments: row.generate_billing_installments || 'nao',
            table: 'payment_conditions'
        });

        setIsModalConditionPaymentOpen(true);
    };

    const handleChange = (fieldName: keyof FormValues, value: string | null | number) => {

        setFormValues({
            ...formValues,
            [fieldName]: value,
        });
    };

    /** modal inserir */
    const closeModalParcelInsert = () => {
        setIsModalConditionPaymentOpen(false);
    };

    /** pede confirmação antes de deletar */
    const confirmDeletConditionPayment = (id: any) => {
        Swal.fire({
            icon: "info",
            title: "Atenção!",
            html: "Deseja deletar esta condição de pagamento, <br> esta ação não poderá ser desfeita!",
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, deletar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteConditionPayment(id);
            }
        });
    }

    /** deleta do servidor */
    const handleDeleteConditionPayment = async (id: any) => {
        if (auth && auth.api_token) {
            let response;

            response = await deleteConditionAPI(id, auth);
            if (response.data.success) {
                Toast.fire({
                    icon: 'success',
                    html: 'Condição de pagamento deletado com sucesso com sucesso.'
                })

                const novoArray = [formValues].filter((item: any) => item === id);
                const newValue = novoArray[0];
                setFormValues(newValue);

                setcontractTypeInstallment((prevContractTypeInstallment: any) => {
                    return prevContractTypeInstallment.filter((elemento: any) => elemento.id !== id);
                });

            }

        }
    }

    const fetchData = async () => {
        try {
            if (auth && auth.api_token) {
                const response = await getTypeInstallment(id || 0, auth);

                if (response.data.success) {

                    setcontractTypeInstallment(response.data.data);
                }
            }
        } catch (error) {
            // Trate o erro aqui
            console.log("Ocorreu um erro ao buscar os dados do responseTypeInstallment:", error);
        }
    }

    /** pede confirm,ação antes de salvar */
    const confirmSaveForm = () => {

        let textAlert = 'Deseja salvar as mudanças feitas nesta condição de pagamento?';
        if (typeSave == 'insert') {
            textAlert = 'Deseja salvar a condição de pagamento?';
        }

        if (!!formValues.due_date && !!formValues.value && formValues.value !== "0.0" && formValues.type_parcel !== '') {
            if (formValues.status === 'Recorrente' && formValues.is_recurring === "0") {
                Swal.fire({
                    icon: "info",
                    title: "Atenção!",
                    text: "Para que a situação seja marcada como 'Recorrente' o campo recorrência deve ser 'Sim'",
                    showCancelButton: false,
                    confirmButtonText: 'Ok, entendi!'
                });
            } else if (formValues.due_date !== "Data inválida") {
                Swal.fire({
                    icon: "info",
                    title: "Atenção!",
                    text: textAlert,
                    showCancelButton: true,
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sim, Salvar!',
                    cancelButtonText: 'Cancelar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        LoadAnimation('Estamos processando...');
                        saveForm();
                    }
                });
            } else {

                Swal.fire({
                    icon: "info",
                    title: "Atenção!",
                    text: "Selecione a data novamente.",
                    showCancelButton: false,
                    confirmButtonText: 'Ok!',
                });
            }
        } else {

            Swal.fire({
                icon: "info",
                title: "Atenção!",
                html: "Preencha todos os campos vazios e assinalados como 'obrigatório'.",
                showCancelButton: false,
                confirmButtonText: 'Ok, entendi!',
            });
        }
    }

    /** Envia para api */
    const saveForm = async () => {

        if (auth && auth.api_token) {
            let response;
            if (typeSave == 'edit') {
                response = await editParcelAPI(formValues, auth);
            } else {

                response = await insertParcelAPI(formValues, id, auth);
            }

            if (response.data.success) {
                Toast.fire({
                    icon: 'success',
                    html: 'Parcela salva com sucesso.'
                })

                formValues.due_date = formatDateToBrString(formValues.due_date);

                if (typeSave === 'insert') {
                    if (response.data.data !== null) {
                        formValues.id = response.data.data.toString();
                    }
                }

                updatedState()

                // setTableData([formValues]);
                // setIsModalEditOpen(false);
                setIsModalConditionPaymentOpen(false);
                fetchData()

            } else {
                Swal.fire({
                    icon: "info",
                    title: "Atenção",
                    html: `Não foi possível salvar, verifique a conexao com interne, <br> ou fale com um administrador do sistema.`,
                    showCancelButton: false,
                    confirmButtonText: 'Entendi',
                });
            }
        }
    }

    useEffect(() => {

        fetchData();
        updatedState()
    }, [id]);

    const updatedState = () => {
        setcontractTypeInstallment((prevData: any) => {

            return prevData.map((dataItem: any) => {
                const conditions = [formValues].find((item: any) => item.id === dataItem.id);

                if (conditions) {
                    const updatedItem = Object.assign({}, dataItem, conditions);
                    return updatedItem;
                }

                return dataItem;
            });
        });
    }

    useEffect(() => {
        // updatedState()
    }, [])


    return (
        <div className="card pt-4 mb-6 mb-xl-9">
            <div className="card-header border-1">
                <div className="card-title">
                    <h2 className="fw-bold mb-0">Condições de pagamento</h2>
                </div>
                <div className="fw-bold mt-4">
                    <button
                        className="btn btn-sm btn-light"
                        onClick={() => handleInsertConditionPayment()}
                    >
                        Inserir condição de pagamento
                    </button>
                </div>
            </div>

            {
                contractTypeInstallment && Array.isArray(contractTypeInstallment) && contractTypeInstallment.length > 0
                    ? (
                        <>
                            {
                                contractTypeInstallment.map((value, index) => {
                                    //    const _divisor = index !== contractTypeInstallment.data - 1 ? <><div className="separator my-2"></div></> : '';
                                    const _divisor = <><div className="separator my-2"></div></>;

                                    let due_date = value.due_date;
                                    if (typeof due_date !== 'undefined' && due_date !== null) {
                                        if (due_date.includes('-')) {
                                            due_date = due_date.split('-');
                                            due_date = due_date[2] + '/' + due_date[1] + '/' + due_date[0];
                                        }
                                    }

                                    let is_recurring = value.is_recurring === 0 || value.is_recurring === '0' ? `Recorrente` : 'Não Recorrente';

                                    let type_parcel = optionsTypeParcelHouse.find(item => item.value === value.type_parcel)?.label || '';

                                    if (auth?.empresa?.type_assinante === "CLIENT") {
                                        type_parcel = optionsTypeParcelCliente.find(item => item.value === value.type_parcel)?.label || '';
                                    }

                                    console.log('auth?.empresa?.type_assinante', auth?.empresa?.type_assinante)

                                    return (
                                        <div key={`type_installment_${value.id}`} id={`condition_payment_${value.id}`} className="card-body mb-n7 pt-0">

                                            <div className="py-0" data-kt-customer-payment-method="row">
                                                <div className="py-3 d-flex flex-stack flex-wrap">
                                                    <a className="d-flex align-items-center collapsible rotate collapsed" data-bs-toggle="collapse" href={`#type_installment_ref_${value.id}`} role="button" aria-expanded="false" aria-controls={`type_installment_ref_${value.id}`}>
                                                        <div className="me-3 rotate-90"><KTSVG path={"/media/icons/duotune/arrows/arr071.svg"} /></div>

                                                        {/* <img src="/metronic8/demo1/assets/media/svg/card-logos/mastercard.svg" className="w-40px me-3" alt="" /> */}

                                                        <div className="me-3">
                                                            <div className="d-flex align-items-center">
                                                                <div className="text-gray-800 fw-bold">
                                                                    Tipo de cobrança
                                                                </div>

                                                                <div className="badge badge-light-primary ms-5">
                                                                    {value.form_payment.toUpperCase()}
                                                                </div>

                                                                <div className="badge badge-light-success ms-5">
                                                                    {type_parcel.toUpperCase()}
                                                                </div>
                                                            </div>
                                                            <div className="text-muted">Quantidade de parcela: {value.number_parcel < 10 ? `0${value.number_parcel}` : value.number_parcel} - 1º Vencimento: {due_date}</div>
                                                        </div>
                                                    </a>

                                                    <div className="d-flex my-3 ms-9">
                                                        {/* <a href="#" className="btn btn-icon btn-active-light-primary w-30px h-30px me-3" data-bs-toggle="modal" data-bs-target="#kt_modal_new_card">
                                                                <span data-bs-toggle="tooltip" data-bs-trigger="hover" aria-label="Edit" data-bs-original-title="Edit" data-kt-initialized="1">
                                                                    <KTSVG path={"/media/icons/duotune/art/art005.svg"}/>
                                                                </span>
                                                            </a> */}
                                                        {/* <button
                                                                className="btn btn-icon btn-active-light-primary w-30px h-30px me-3"
                                                                onClick={() => handleEditConditionPayment(value)}
                                                            >
                                                                <span data-bs-toggle="tooltip" data-bs-trigger="hover" aria-label="Edit" data-bs-original-title="Edit" data-kt-initialized="1">
                                                                    <KTSVG path={"/media/icons/duotune/art/art005.svg"}/>
                                                                </span>
                                                            </button> */}

                                                        <button
                                                            className="btn btn-icon btn-active-light-primary w-30px h-30px me-3"
                                                            onClick={() => confirmDeletConditionPayment(value.id)}
                                                        >
                                                            <KTSVG path={"/media/icons/duotune/general/gen027.svg"} />
                                                        </button>

                                                        {/* <a href="#" className="btn btn-icon btn-active-light-primary w-30px h-30px me-3" data-bs-toggle="tooltip" data-kt-customer-payment-method="delete" aria-label="Delete" data-bs-original-title="Delete" data-kt-initialized="1">
                                                                <KTSVG path={"/media/icons/duotune/general/gen027.svg"}/>
                                                            </a> */}

                                                    </div>
                                                </div>

                                                <div id={`type_installment_ref_${value.id}`} className="fs-6 ps-10 collapse" data-bs-parent={`#type_installment_${value.id}`}>
                                                    <div className="d-flex flex-wrap py-5">
                                                        <div className="flex-equal me-5">
                                                            <table className="table table-flush fw-semibold gy-1">
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="min-w-200px w-250px">
                                                                            <label className="w-250px">Quantidade de parcelas</label>
                                                                            <div className="fw-normal text-gray-600">{value.number_parcel < 10 ? `0${value.number_parcel}` : value.number_parcel}</div>
                                                                        </td>

                                                                        <td>
                                                                            <label className="w-150px">Valor da parcela</label>
                                                                            {/* <div className="fw-normal text-gray-600">{value.value}</div> */}
                                                                            <div className="fw-normal text-gray-600">{'R$ ' + value?.value || '...'}</div>
                                                                        </td>

                                                                        <td>
                                                                            <label className="w-150px">Recorrência</label>
                                                                            <div className="fw-normal text-gray-600">{is_recurring}</div>
                                                                        </td>

                                                                        <td>
                                                                            <label className="w-150px">Tipo de parcela</label>
                                                                            <div className="fw-normal text-gray-600">{type_parcel}</div>
                                                                        </td>


                                                                    </tr>
                                                                    <tr>

                                                                        <td>
                                                                            <label className="w-150px">1º Vencimento</label>
                                                                            <div className="fw-normal text-gray-600">
                                                                                {due_date || "00/00/0000"}
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            <label className="w-150px">Taxa de juros</label>
                                                                            <div className="fw-normal text-gray-600">{value?.interest_rate + '%' || '...'}</div>
                                                                        </td>

                                                                        <td>
                                                                            <label className="w-150px">Índice de Reajustes</label>
                                                                            <div className="fw-normal text-gray-600">{value?.readjustment_index || ''}</div>
                                                                        </td>

                                                                        <td>
                                                                            <label className="w-150px">Parcelas Geradas?</label>
                                                                            <div className="fw-normal text-gray-600">{value?.generate_billing_installments.toUpperCase() || ''}</div>
                                                                        </td>

                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {_divisor}
                                        </div>
                                    )

                                })
                            }
                        </>
                    )
                    : (<><img src={"/media/illustrations/sketchy-1/5.png"} style={{ height: '300px', objectFit: 'scale-down' }} /></>)
            }

            {isModalConditionPaymentOpen &&
                <FormInsertParcel
                    closeModal={closeModalParcelInsert}
                    handlerSaveForm={confirmSaveForm}
                    formValues={formValues}
                    handleChange={handleChange}
                />
            }
        </div>
    );
}