/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../helpers'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useDispatch, useSelector } from 'react-redux'
import { setFilter } from '../../../../../app/redux/filter/filterSlice'
import { useLocation, useParams } from 'react-router-dom'
import { resetFilter } from '../../../../../app/redux/filter/resetSlice'
import useNavegationZustand from '../../../../../app/zustand/NavegationZustand'
import usePermissionStore from '../../../../../app/zustand/PermissionStore'
import * as authHelper from './../../../../../app/modules/auth/core/AuthHelpers';
import { useAuth } from '../../../../../app/modules/auth'


interface StoreState {
    currentRoute: string;
    navigate: (path: string) => string;
}

const SidebarMenuMain = () => {

    // const navigateStore = useNavegationZustand();
    const permissionStore = usePermissionStore();
    const { uuid } = useAuth()
    const { uui } = useParams();

    if (!uui && !uuid) {
        window.location.href = `auth/`;
    }

    let tokenToValidate = '';

    if (typeof uui === "string") {
        tokenToValidate = uui;
    } else if (typeof uuid === "string") {
        tokenToValidate = uuid;
    } else if (typeof uuid === "object") {
        tokenToValidate = uuid?.uui as string;
    }

    if (!tokenToValidate) {
        window.location.href = `auth/${tokenToValidate}`;
    }
    // console.log('SidebarMenuMain tokenToValidate ', tokenToValidate, typeof uuid)
    // console.log('SidebarMenuMain permissionStore ', permissionStore)

    const intl = useIntl()
    const location = useLocation();

    const dispatch = useDispatch();

    const filterState: any = useSelector((state: any) => state.filter);

    useEffect(() => {

        const newObject = Object.fromEntries(
            Object.entries(filterState).map(([key, value]) => [key, ''])
        );

        dispatch(setFilter(newObject));

    }, [location.pathname, permissionStore.currentRoute]);

    return (
        <>
            {
                permissionStore.routesMenu && permissionStore.routesMenu.map((menu, index) => (
                    <div key={index} onClick={() => { permissionStore.setNavigate(menu.allowedTo, true) }}>
                        <SidebarMenuItem
                            to={`${menu.to}/${tokenToValidate}`}
                            icon={menu.icon}
                            title={intl.formatMessage({ id: menu.intl })}
                            fontIcon='bi-app-indicator'
                        // onClick={routeClick}

                        />
                    </div>
                ))
            }
            {/* <a type='button' onClick={() => permissionStore.setNavigate('dashboard')}>
                <SidebarMenuItem
                    to='/dashboard/access'
                    icon='/media/icons/duotune/art/art002.svg'
                    title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                    fontIcon='bi-app-indicator'
                    // onClick={routeClick}

                />
            </a>

            <a type='button' onClick={() => permissionStore.setNavigate('contract')}>
                <SidebarMenuItem 
                    to='/contract/access' 
                    title='Contratos' 
                    // hasBullet={true} 
                    icon='/media/icons/duotune/general/gen005.svg'
                />
            </a> */}
            {/* </SidebarMenuItemWithSub> */}

            {/* <a type='button' onClick={() => permissionStore.setNavigate('client')}>
                <SidebarMenuItem
                    to='/client/access'
                    icon='/media/icons/duotune/communication/com013.svg'
                    title={'Clientes'}
                    fontIcon='bi-app-indicator'
                />
            </a>
            
            <a type='button' onClick={() => permissionStore.setNavigate('corretor')}>
                <SidebarMenuItem
                    to='/corretor/access'
                    icon='/media/icons/duotune/communication/com014.svg'
                    title={'Corretores'}
                    fontIcon='bi-app-indicator'
                />
            </a>
            
            <a type='button' onClick={() => permissionStore.setNavigate('product')}>
                <SidebarMenuItem
                    to='/product/access'
                    icon='/media/icons/duotune/ecommerce/ecm008.svg'
                    title={'Produtos'}
                    fontIcon='bi-app-indicator'
                />
            </a>

            <a type='button' onClick={() => permissionStore.setNavigate('unit')}>
                <SidebarMenuItem 
                    to='/unit/access' 
                    title='Unidades' 
                    // hasBullet={true} 
                    icon='/media/icons/duotune/layouts/lay002.svg'
                />
            </a>

            <a type='button' onClick={() => permissionStore.setNavigate('payment')}>
                <SidebarMenuItem 
                    to='/payment/access' 
                    title='Pagamentos'
                    icon='/media/icons/duotune/finance/fin002.svg'
                />
            </a>
            
            <a type='button' onClick={() => permissionStore.setNavigate('parcel')}>
                <SidebarMenuItem 
                    to='/parcel/access' 
                    title='Parcelas Canceladas'
                    icon='/media/icons/duotune/general/gen036.svg'
                />
            </a>

            <a type='button' onClick={() => permissionStore.setNavigate('configuration')}>
                <SidebarMenuItem
                    to='/configuration/access'
                    icon='/media/icons/duotune/coding/cod001.svg'
                    title={'Configuração'}
                    fontIcon='bi-app-indicator'
                />
            </a> */}

        </>
    )
}

export { SidebarMenuMain }
