import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import { PageLink, PageTitle } from "../../layout/core"
import { DetailsContract } from "./DetailsContract"
import { HistoricoWrapper } from "../../../app/pages/contrato/HistoricoWrapper"
import { Installment } from "./Installment"
import { TabHeaderContract } from "./TabHeaderContract"
import { TypeInstallment } from "./TypeInstallment"

const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Contrato',
        path: 'contract/access',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const TabRouteContract = ({ id }: { id: number }) => (
    <Routes>
        <Route
            element={
                <>
                    <TabHeaderContract id={id || 0} />
                    <Outlet />
                </>
            }
        >
            <Route
                path={`overview/:uui`}
                element={
                    <>
                        <PageTitle breadcrumbs={profileBreadCrumbs} >Visão Contrato</PageTitle>
                        <DetailsContract id={id || 0} />
                        <TypeInstallment id={id || 0} />
                    </>
                }
            />
            <Route
                path={`installment/:uui`}
                element={
                    <>
                        <PageTitle breadcrumbs={profileBreadCrumbs} >Parcelas</PageTitle>
                        <Installment id={id || 0} />
                    </>
                }
            />

            <Route
                path={`historico/:uui`}
                element={
                    <>
                        <PageTitle breadcrumbs={profileBreadCrumbs} >Histórico</PageTitle>
                        <HistoricoWrapper id={id || 0} />
                    </>
                }
            />

            <Route index element={<Navigate to={`overview/:uui`} />} />
        </Route>
    </Routes>
)

export default TabRouteContract