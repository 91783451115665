import axios, { AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse, CancelToken, CancelTokenSource } from 'axios';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getToken } from '../../../../app/redux/api/auth/getToken';

import * as authHelper from '../../../../app/modules/auth/core/AuthHelpers'

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});

interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
    cancelled?: boolean;
    isFormFileData?: boolean;
}


const dataLocalStorage = getToken();
const uuid = authHelper.getAuthUui();

const BaseServiceHttp = <T = any>(configRequest?: AxiosRequestConfig): AxiosInstance => {

    // const source: CancelTokenSource = axios.CancelToken.source();

    const axiosInstance = axios.create({
        // Configurações globais, se necessário
        baseURL: process.env.REACT_APP_API_URL,
        // timeout: 1000,
        headers: {
            'Authorization': `Bearer ${dataLocalStorage?.token}`,
            'X-UUID-Assinante': uuid ? String(uuid) : '',
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': 'http://localhost:3011',
        },

        // cancelToken: source.token,
    });

    // Configurar um interceptor global para respostas
    axiosInstance.interceptors.response.use(
        (response: AxiosResponse) => {
            // Adicionar lógica global após a recepção da resposta

            return response;
        },
        (error: any) => {
            // Lidar com erros de resposta
            const { response } = error;

            if (response) {
                const { status, data } = response;

                if (status === 401) {
                    // Redirecionar para a página de autenticação em caso de erro 401
                    window.location.href = '/auth';
                } else if (status === 403) {
                    if (data.type === 'message') {
                        // Exibir um alerta em caso de erro 403
                        Toast.fire({
                            icon: 'error',
                            title: data.messages.error,
                        });
                    } else if (data.type === 'redirect') {
                        // window.location.href = '/403';
                        window.location.href = '/permission';
                    }

                    response.config.cancelled = true;

                    // Manipular a mensagem personalizada do servidor
                    // console.log('Erro personalizado:', data, response);

                    // localStorage.setItem('permissionBasic', JSON.stringify(data));
                    return Promise.reject(error);
                } else {
                    console.error('Erro status:', status);
                }
            } else if (error.request) {
                // A requisição foi feita, mas nenhuma resposta foi recebida
                console.error('Sem resposta recebida:', error.request);
            } else {
                // Alguma coisa aconteceu ao configurar a requisição que acionou este erro
                console.error('Erro durante a configuração da requisição:', error.message);

                // Trate especificamente os erros de CORS ou rede
                if (error.message.includes('CORS')) {
                    console.error('Erro de CORS:', error.message);
                    // Implemente a lógica de tratamento de erros de CORS, se necessário
                } else {
                    // Outra lógica de tratamento de erros de rede
                }
            }

            return Promise.reject(error);
        }

    );

    // Configurar um interceptor global para requisições ao enviasr
    axiosInstance.interceptors.request.use(
        (request: ExtendedAxiosRequestConfig) => {
            console.log('Fazendo requisição:', request);

            // Garantir que 'headers' seja definido
            request.headers = request.headers || {};

            if (uuid) {
                request.headers['X-UUID-Assinante'] = uuid ? String(uuid) : '';
            } else {
                console.warn('UUID não encontrado para o cabeçalho.');
            }

            // Verificar se a solicitação foi cancelada e interromper a cadeia de promessas
            if (request.cancelled) {
                return Promise.reject(new axios.Cancel('Requisição cancelada devido a falta de permissão! status 403'));
            }

            if (request.isFormFileData) {
                // Configurar cabeçalho para envio de arquivos
                request.headers['Content-Type'] = 'multipart/form-data';
            }

            return request;
        },
        (error: AxiosError) => {
            // Lidar com erros de requisição
            console.error('Erro na requisição:', error);
            return Promise.reject(error);
        }
    );

    // Aplicar configurações personalizadas, se fornecidas
    if (configRequest) {
        Object.assign(axiosInstance.defaults, configRequest);
    }

    return axiosInstance;
};

export default BaseServiceHttp;


