import axios from "axios";
import { useEffect, useState } from "react";
import * as yup from 'yup';
import InputMask from 'react-input-mask';
import { SchemaProduct } from "./SchemaProduct";
// import { toAbsoluteUrl } from "../../../_metronic/helpers";
import Select from "react-select";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../app/modules/auth";
import { getDataProduct, getImagensProduct, saveProduct } from "../../request/product";

// Import React FilePond
import { registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { formProduct } from "../../interface/I_Product";
import { Importacao } from "../../../_metronic/layout/components/configurations/importacao/Importacao";
import { Upload } from "../../interface/I_Files";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { TagifyListCustom } from "../../../_metronic/layout/components/TagifyListCustom";
// import { IBank, optionsBillingBanks } from "../../interface/I_Configurations";
import { Option } from "../../interface/I_General";
import { getBanks } from "../../request/configurations/_request_banks";

interface IBankProduct {
    id_banco: string
}

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Produto em edição',
        path: '/Produtos/',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


function ProductEditWrapper() {
    const { id } = useParams();

    const { auth, logout, setCurrentUser } = useAuth();
    const [files, setFiles] = useState<any[]>([]);

    const API_URL = process.env.REACT_APP_API_URL;

    const [formValues, setFormValues] = useState<formProduct>({
        id: id,
        name: "",
        name_owner: "",
        cpf_cnpj: "",
        cep: "",
        phone: "",
        rg: "",
        country: "",
        number: "",
        address: "",
        neighborhood: "",
        city: "",
        state: "",
        path_imge: "",
        files: [],
        description: "",
        billing_payment_to_api: [],
        id_banco: ''
    });


    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({
        name: "",
        // name_owner: "",
        // cpf_cnpj: "",
        // cep: "",
        // phone: "",
        // rg: "",
        // country: "",
        // number: "",
        // address: "",
        // neighborhood:"",
        // city: "",
        // state: "",
        // path_imge: "",
        // description: "",
    });


    const [banks, setBanks] = useState<any>([]);

    // recuepra dados do produto
    const getProduct = async () => {
        if (auth && auth.api_token && id) {
            let response = await getDataProduct(id, auth);
            if (response.data.data !== null) {

                const product: any = response.data.data;
                setFormValues(product);
            }
        }
    }

    useEffect(() => {
        getProduct();
        getImagePoducts();
    }, [])

    useEffect(() => {
        loadBanks();
    }, [])

    const loadBanks = async () => {

        if (auth && auth.api_token) {
            try {
                const response = await getBanks(auth);
                if (response.data && response.data && Array.isArray(response.data)) {

                    const newListBanks = response.data.map((banks: { id: any; beneficiary: string }) => ({
                        value: banks.id,
                        label: banks.beneficiary,
                    }));

                    setBanks(newListBanks);
                }
            } catch (error) {
                console.error('Erro ao carregar bancos:', error);
            }

        }
    }


    // envia formulario
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setFormValues((lastData) => ({
            ...lastData,
            files: files,
        }));
        try {
            await SchemaProduct.validate(formValues, { abortEarly: false });
            setFormErrors({});

            const responseSaved = await saveProduct(formValues);
            if (responseSaved.data.success) {
                Swal.fire({
                    icon: "success",
                    title: "Parabéns",
                    text: `Produto salvo com sucesso`,
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                });
            } else if (responseSaved.data.status && responseSaved.data.status !== 403) {
                Swal.fire({
                    icon: "info",
                    title: "Atenção",
                    html: `Não foi possível salvar, verifique a conexao com internet, <br> ou fale com um administrador do sistema.`,
                    showCancelButton: false,
                    confirmButtonText: 'Entendi',
                });
            }

        } catch (errors) {

            if (errors instanceof yup.ValidationError) {
                const validationErrors: { [key: string]: string } = {};
                errors.inner.forEach((error) => {

                    validationErrors[error.path as string] = error.message
                });
                setFormErrors(validationErrors);
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (name === 'path_imge') {
            const path_imge = event.target.defaultValue ? event.target.defaultValue : '';
            if (path_imge) {
                setFormValues({
                    ...formValues,
                    path_imge: path_imge as string,
                });
            } else {

                setFormValues({
                    ...formValues,
                    path_imge: '',
                });
            }
            event.target.value = '';
        } else {

            setFormValues((prevValues) => ({ ...prevValues, [name]: value || '' }));
            // Valide o campo usando o schema do Yup
            yup
                .reach(SchemaProduct, name)
                .validate(value)
                .then(() => {
                    // Se o campo for válido, remove o erro correspondente
                    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
                })
                .catch((error: { message: any; }) => {
                    // Se o campo for inválido, define o erro correspondente
                    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
                });
        }


    };

    // cep
    const handleCepBlur = async () => {

        const cep = formValues && formValues?.cep ? formValues?.cep.replace(/\D/g, '') : '';

        if (!!cep) {
            return;
        }

        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

            if (response.data.erro) {
                // CEP não encontrado
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Cep não encontrado'
                });
                setFormValues({
                    ...formValues,
                    cep: '',
                    address: '',
                    neighborhood: '',
                    city: '',
                    state: '',
                    country: '',
                });
            } else {
                setFormValues({
                    ...formValues,
                    cep: response.data.cep || "",
                    address: response.data.logradouro || "",
                    neighborhood: response.data.bairro || "",
                    city: response.data.localidade || "",
                    state: response.data.uf || "",
                    country: response.data.pais || "",
                });
            }

        } catch (error) {

            setFormValues({
                ...formValues,
                cep: '',
                address: '',
                neighborhood: '',
                city: '',
                state: '',
                country: '',
            });
        }
    };

    const getImagePoducts = async () => {

        if (id && auth) {
            const responseImage = await getImagensProduct(id, auth);
            if (responseImage.data.data && Array.isArray(responseImage.data.data)) {
                setFiles(responseImage.data.data);
            }
        }
    }

    const handleFile = (isLoad: boolean) => {
        if (isLoad)
            getImagePoducts();
    };

    const proposUpload: Upload = {
        idUppy: 'upload',
        idImageEditor: 'ImageEditor',
        plugins: ['ImageEditor', 'ScreenCapture', 'Webcam'],
        isDebug: true,
        isAutoProceed: false,
        isAllowMultipleUploads: true,
        isShowProgressDetails: true,
        isHideUploadButton: false,
        fileTypes: ['.png', '.jpg', '.jpeg'],
        endPoint: `uploadImagesProduct/${id}`,
        width: 'auto',
        height: '350px',
        filesData: files,
        isMessageReturn: false,
        urlDelete: `deleteImageProduct`
    }

    const onSelectTagify = (type: string, data: any) => {

        if (type === 'addAll') {
            setFormValues((prevState) => {
                const newData = data.filter((item: any) => {
                    if (!prevState.billing_payment_to_api) {
                        return true;
                    }
                    return !prevState.billing_payment_to_api.some(existingItem => existingItem === item)
                });

                return {
                    ...prevState,
                    billing_payment_to_api: prevState.billing_payment_to_api ? [...prevState.billing_payment_to_api, ...newData] : newData,
                };
            });

        } else if (type === 'add') {
            if (formValues?.billing_payment_to_api && !formValues.billing_payment_to_api.includes(data)) {
                setFormValues((prevState) => ({
                    ...prevState,
                    ['billing_payment_to_api']: prevState.billing_payment_to_api && prevState.billing_payment_to_api.length > 0 ? [...prevState.billing_payment_to_api, data] : [data],
                }));
            }
        } else {

            setFormValues((prevState) => {
                const billingPaymentToApi = prevState?.billing_payment_to_api || [];
                const updatedItems = billingPaymentToApi.filter(item => item !== data);
                // const updatedItems = prevState.billing_payment_to_api.filter(item => item !== data);
                return {
                    ...prevState,
                    billing_payment_to_api: updatedItems,
                };
            });
        }
    };

    const handleSelectChange = <K extends keyof IBankProduct>(selectedOption: Option | null, fieldName: K) => {
        const value = selectedOption ? selectedOption.value : "";
        const label = selectedOption ? selectedOption.label : "";
        setFormValues((prevState: any) => ({
            ...prevState,
            [fieldName]: value,
        }));

    };

    useEffect(() => {

        setFormValues((prevState) => ({
            ...prevState,
            billing_payment_to_api: formValues.billing_payment_to_api,
        }));
    }, [formValues.billing_payment_to_api]);

    return (
        <>
            <div className="app-container  container-xxl ">
                <div className="d-flex flex-column flex-lg-row ms-n11">
                    <div className="flex-lg-row-fluid ms-lg-5 ms-xl-5">
                        <PageTitle breadcrumbs={profileBreadCrumbs}>Produto</PageTitle>

                        <div className="card mb-5 mb-xl-10">
                            <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                                <div className="card-title m-0">
                                    <h3 className="fw-bold m-0">Dados gerais</h3>
                                </div>
                            </div>

                            <div id="kt_account_settings_profile_details" className="collapse show">
                                <form id="kt_product_form"
                                    onSubmit={handleSubmit}
                                    className="form fv-plugins-bootstrap5 fv-plugins-framework">
                                    <div className="card-body border-top p-9">
                                        <div className="row mb-6">
                                            <Importacao
                                                propsFiles={proposUpload}
                                                onFile={handleFile}
                                            />

                                            <label className="col-lg-12 mt-6 col-form-label fw-semibold fs-6">Imagem do produto</label>

                                            <div className="col-lg-12 mt-6">
                                                {
                                                    proposUpload.filesData && proposUpload.filesData.length > 0 ? (
                                                        proposUpload.filesData.map((files: any, index: number) => {
                                                            const base64Image = `data:${files.type};base64,${files.content}`; // Substitua com o seu código base64

                                                            const style = {
                                                                backgroundImage: `url(${base64Image})`,
                                                                backgroundRepeat: 'no-repeat',
                                                                backgroundSize: 'cover',
                                                            };

                                                            let checked: boolean = false;
                                                            if (!!files.url) {
                                                                if (files.url === formValues.path_imge) {
                                                                    checked = true;
                                                                } else if (index === 0) {
                                                                    checked = true;
                                                                }
                                                            }

                                                            return (
                                                                <div className="image-input image-input-outline me-5" key={files.id} style={style}>
                                                                    <div className="image-input-wrapper w-125px h-125px" style={style} ></div>
                                                                    <span
                                                                        className="form-check-input btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                                        data-kt-image-input-action="remove"
                                                                    >

                                                                        <input
                                                                            className=""
                                                                            type="radio"
                                                                            name="path_imge"
                                                                            value={files.url || ''}
                                                                            onChange={handleChange}
                                                                            checked={checked}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            )
                                                        })
                                                    ) : (
                                                        <>
                                                            <div className="row text-center">
                                                                <div className="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row w-100 p-5 mb-10">

                                                                    <i className="ki-duotone ki-search-list fs-2hx text-primary me-4 mb-5 mb-sm-0">
                                                                        <span className="path1"></span><span className="path2"></span><span className="path3"></span>
                                                                    </i>


                                                                    <div className="d-flex flex-column pe-0 pe-sm-10">
                                                                        <h4 className="fw-semibold">Ei, olha aqui...</h4>
                                                                        <span>Ainda não tem uma imagem para este produto, você pode adicionar quantas quiser e escolher qual usar no momento.</span>
                                                                    </div>

                                                                    <button type="button" className="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto" data-bs-dismiss="alert">
                                                                        <i className="ki-duotone ki-cross fs-1 text-primary"><span className="path1"></span><span className="path2"></span></i>                    </button>

                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }

                                                <div className="form-text mt-7">Selecione um dos arquivos, como padrão para exibir em seu produto, ainda é necessário salvar.</div>
                                            </div>
                                        </div>

                                        <div className="separator d-flex flex-center mt-8 mb-8">
                                            <span className="text-uppercase bg-body fs-7 fw-semibold text-muted px-3">Dados do Produto</span>
                                        </div>

                                        <div className="row py-9">
                                            <div className="col-sm-12 col-md-4 col-lg-4 fv-row fv-plugins-icon-container form-floating mb-7">

                                                <input type="text" name="name" value={formValues.name || ''} id="name" onChange={handleChange} className={`form-control ${formErrors.name ? ' is-invalid' : ''}`} placeholder="Nome" />
                                                <label className="ms-3" htmlFor="name">Nome do Produto</label>
                                                {formErrors.name && (
                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.name}</div>
                                                )}

                                            </div>

                                            <div className="col-sm-12 col-md-4 col-lg-4 fv-row fv-plugins-icon-container form-floating mb-7">

                                                <InputMask
                                                    className={`form-control `}
                                                    // className={`form-control ${formErrors.cep ? ' is-invalid' : ''}`}
                                                    placeholder="Cep"
                                                    id="cep"
                                                    mask="99999-999"
                                                    name="cep"
                                                    value={formValues?.cep || ''}
                                                    // unmask
                                                    onBlur={handleCepBlur}
                                                    onChange={handleChange}
                                                />
                                                <label className="ms-3" htmlFor="cep">Cep</label>

                                                {formErrors && formErrors?.cep && (
                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors?.cep}</div>
                                                )}

                                            </div>

                                            <div className="col-sm-12 col-md-4 col-lg-4 fv-row fv-plugins-icon-container form-floating mb-7">

                                                {/* <input type="text" name="address" id="address" onChange={handleChange} className={`form-control ${formErrors.address ? ' is-invalid' : ''}`}  placeholder="Endereço" value={formValues.address || ''} /> */}
                                                <input type="text" name="address" id="address" onChange={handleChange} className={`form-control`} placeholder="Endereço" value={formValues?.address || ''} />
                                                <label className="ms-3" htmlFor="address">Endereço</label>
                                                {formErrors && formErrors?.address && (
                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.address}</div>
                                                )}

                                            </div>

                                            <div className="col-sm-12 col-md-2 col-lg-2 fv-row fv-plugins-icon-container form-floating mb-7">

                                                {/* <input type="text" name="number" id="" onChange={handleChange} className={`form-control ${formErrors.number ? ' is-invalid' : ''}`}  placeholder="Número" value={formValues.number || ''} /> */}
                                                <input type="text" name="number" id="" onChange={handleChange} className={`form-control ${formErrors && formErrors?.number ? ' is-invalid' : ''}`} placeholder="Número" value={formValues.number || ''} />
                                                <label className="ms-3" htmlFor="number">Número</label>
                                                {formErrors && formErrors?.number && (
                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.number}</div>
                                                )}
                                            </div>

                                            <div className="col-sm-12 col-md-6 col-lg-6 fv-row fv-plugins-icon-container form-floating mb-7">

                                                <input type="text" name="neighborhood" onChange={handleChange} className={`form-control ${formErrors && formErrors?.neighborhood ? ' is-invalid' : ''}`} placeholder="Bairro" value={formValues.neighborhood || ''} />
                                                <label className="ms-3" htmlFor="">Bairro</label>
                                                {formErrors && formErrors?.neighborhood && (
                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.neighborhood}</div>
                                                )}

                                            </div>

                                            <div className="col-sm-12 col-md-4 col-lg-4 fv-row fv-plugins-icon-container form-floating mb-7">

                                                <input type="text" name="city" id="city" onChange={handleChange} className={`form-control ${formErrors && formErrors?.city ? ' is-invalid' : ''}`} placeholder="Cidade" value={formValues.city || ''} />
                                                <label className="ms-3" htmlFor="city">Cidade</label>
                                                {formErrors && formErrors?.city && (
                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.city}</div>
                                                )}
                                            </div>

                                            <div className="col-sm-12 col-md-4 col-lg-4 fv-row fv-plugins-icon-container form-floating mb-7">

                                                <input type="text" name="state" id="state" onChange={handleChange} className={`form-control ${formErrors && formErrors?.state ? ' is-invalid' : ''}`} placeholder="Estado" value={formValues.state || ''} />
                                                <label className="ms-3" htmlFor="state">Estado</label>
                                                {formErrors && formErrors?.state && (
                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.state}</div>
                                                )}
                                            </div>

                                            <div className="col-sm-12 col-md-4 col-lg-4 fv-row fv-plugins-icon-container form-floating mb-7">

                                                <input type="text" name="country" id="country" onChange={handleChange} className={`form-control ${formErrors && formErrors?.country ? ' is-invalid' : ''}`} placeholder="Nacionalidade" value={formValues.country || ''} />
                                                <label className="ms-3" htmlFor="country">Nacionalidade</label>
                                                {formErrors && formErrors?.country && (
                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.country}</div>
                                                )}

                                            </div>

                                            <div className="col-sm-12 col-md-4 col-lg-4 fv-row fv-plugins-icon-container form-floating mb-7">
                                                <input type="text" name="description" id="description" onChange={handleChange} className={`form-control ${formErrors && formErrors?.description ? ' is-invalid' : ''}`} placeholder="EX: ABCVENDAS" value={formValues.description || ''} />
                                                <label className="ms-3" htmlFor="description">Descrição</label>
                                                {formErrors && formErrors?.description && (
                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.description}</div>
                                                )}

                                            </div>


                                        </div>

                                        <div className="separator d-flex flex-center mb-10">
                                            <span className="text-uppercase bg-body fs-7 fw-semibold text-muted px-3">Configuração de cobrança</span>
                                        </div>
                                        <div className=" row ">
                                            <div className="col-sm-12 col-md-12 col-lg-12 fv-row fv-plugins-icon-container form-floating mb-7">

                                                <TagifyListCustom
                                                    onSelectTagify={onSelectTagify}
                                                    selectedValues={formValues?.billing_payment_to_api || []}
                                                    className={'w-100 h-75px'}
                                                />
                                                {/* <input type="text" id="name_owner" name="name_owner" onChange={handleChange} className={`form-control ${formErrors.name_owner ? ' is-invalid' : ''}`} placeholder="Sobrenome" value={formValues.name_owner || ''} />
                                                <label className="ms-3" htmlFor="name_owner">Proprietário</label>
                                                {formErrors.name_owner && (
                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.name_owner}</div>
                                                )} */}


                                            </div>
                                            <div className="col-sm-12 col-md-12 col-lg-12 fv-row fv-plugins-icon-container form-floating mb-7">

                                                <Select
                                                    styles={{ container: (base: any) => ({ ...base, zIndex: 11 }) }}
                                                    className="w-100"
                                                    placeholder="Banco"
                                                    value={
                                                        !!formValues?.id_banco
                                                            ? banks.find((option: Option) => option.value === formValues?.id_banco)
                                                            : { value: '', label: 'Selecione' }
                                                    }
                                                    // value={formValues.id_banco !== '0' && formValues.id_banco !== '' ? { value: formValues.id_banco, label: optionsBillingBanks.find(option => option.value === formValues.id_banco)?.label } : null}
                                                    onChange={(selectedOption: any) => handleSelectChange(selectedOption as Option | null, "id_banco")}
                                                    options={banks}
                                                />


                                            </div>
                                        </div>

                                        <div className="separator d-flex flex-center mb-10">
                                            <span className="text-uppercase bg-body fs-7 fw-semibold text-muted px-3">Dados do Proprietário</span>
                                        </div>

                                        <div className=" row ">
                                            <div className="col-sm-12 col-md-4 col-lg-6 fv-row fv-plugins-icon-container form-floating mb-7">

                                                <input type="text" id="name_owner" name="name_owner" onChange={handleChange} className={`form-control ${formErrors && formErrors?.name_owner ? ' is-invalid' : ''}`} placeholder="Sobrenome" value={formValues.name_owner || ''} />
                                                <label className="ms-3" htmlFor="name_owner">Proprietário</label>
                                                {formErrors && formErrors?.name_owner && (
                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.name_owner}</div>
                                                )}
                                            </div>

                                            <div className="col-sm-12 col-md-4 col-lg-6 fv-row fv-plugins-icon-container form-floating mb-7">

                                                <InputMask
                                                    className={`form-control form-control-lg ${formErrors && formErrors?.cpf_cnpj ? ' is-invalid' : ''}`} placeholder="cpf_cnpj"
                                                    mask="999.999.999-99"
                                                    name="cpf_cnpj"
                                                    value={formValues.cpf_cnpj || ''}
                                                    onChange={handleChange}
                                                />
                                                <label className="ms-3" htmlFor="">CPF/CNPJ</label>
                                                {formErrors && formErrors?.cpf_cnpj && (
                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.cpf_cnpj}</div>
                                                )}
                                            </div>

                                            <div className="col-sm-12 col-md-4 col-lg-6 fv-row fv-plugins-icon-container form-floating mb-7">

                                                <input type="text" name="rg" id="rg" onChange={handleChange} className={`form-control ${formErrors && formErrors?.rg ? ' is-invalid' : ''}`} placeholder="Rg" value={formValues.rg || ''} />
                                                <label className="ms-3" htmlFor="rg">Rg</label>
                                                {formErrors && formErrors?.rg && (
                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.rg}</div>
                                                )}
                                            </div>

                                            <div className="col-sm-12 col-md-4 col-lg-6 fv-row fv-plugins-icon-container form-floating mb-7">

                                                <InputMask
                                                    className={`form-control ${formErrors && formErrors?.phone ? ' is-invalid' : ''}`}
                                                    placeholder="Telefone"
                                                    id="phone"
                                                    mask="(99) 99999-9999"
                                                    name="phone"
                                                    value={formValues.phone || ''}
                                                    onChange={handleChange}
                                                />
                                                <label className="ms-3" htmlFor="phone">Telefone</label>

                                                {formErrors && formErrors?.phone && (
                                                    <div className="fv-plugins-message-container invalid-feedback">{formErrors.phone}</div>
                                                )}
                                            </div>
                                        </div>

                                    </div>

                                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                                        {/* <button type="reset" className="btn btn-light btn-active-light-primary me-2">Discard</button> */}
                                        <button type="submit" className="btn btn-primary" id="kt_product_submit">Savar</button>
                                    </div>

                                    <input type="hidden" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default ProductEditWrapper

