import { AuthUui } from '../../../interface/I_Auth'
import { I_Empresa } from '../../../interface/I_Empresa'
import { AuthModel } from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const auth = getAuth()
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

const getAuthUui = (): AuthUui | undefined => {
  if (typeof localStorage === 'undefined') {
    console.warn('localStorage não está disponível.')
    return
  }

  const lsUui: string | null | undefined = localStorage.getItem('auth-uui')
  if (!lsUui) {
    return;
  }

  try {
    const uui: AuthUui = JSON.parse(lsUui) as AuthUui
    if (uui) {
      // You can easily check auth_token expiration also
      return uui
    }
  } catch (error) {
    console.error('GET AUTH UUI LOCAL STORAGE PARSE ERROR', error)
    return;
  }
}

const setAuthUui = (uui: AuthUui | undefined) => {
  if (!localStorage) {
    return
  }

  try {
    const lsUui = JSON.stringify(uui?.uui)
    localStorage.setItem('auth-uui', lsUui)
  } catch (error) {
    console.error('SET AUTH UUI LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuthUui = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem('auth-uui')
  } catch (error) {
    console.error('AUTH UUI LOCAL STORAGE REMOVE ERROR', error)
  }
}


const getAssinante = (): I_Empresa | undefined => {
  if (typeof localStorage === 'undefined') {
    console.warn('localStorage não está disponível.')
    return
  }

  const lsAssinante = localStorage.getItem('assinante')
  if (!lsAssinante) {
    return;
  }

  try {
    const empresa: I_Empresa = JSON.parse(lsAssinante) as I_Empresa
    if (empresa) {
      // You can easily check auth_token expiration also
      return empresa
    }
  } catch (error) {
    console.error('GET EMPRESA ASSINANTE LOCAL STORAGE PARSE ERROR', error)
    return;
  }
}

const setAssinante = (assinante: I_Empresa | undefined) => {
  if (!localStorage) {
    return
  }

  try {
    const lsAssinante = JSON.stringify(assinante)
    localStorage.setItem('assinante', lsAssinante)
  } catch (error) {
    console.error('SET EMPRESA ASSINANTE LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAssinante = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem('assinante')
  } catch (error) {
    console.error('EMPRESA ASSINANTE LOCAL STORAGE REMOVE ERROR', error)
  }
}

export { getAuth, setAuth, removeAuth, removeAuthUui, getAuthUui, setAuthUui, getAssinante, setAssinante, removeAssinante, AUTH_LOCAL_STORAGE_KEY }
