/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login, validaUuid } from '../core/_requests'
import { useAuth } from '../core/Auth'
import usePermissionStore from '../../../zustand/PermissionStore'
import { permissionsUser } from '../../../request/permission'

import * as authHelper from './../../../modules/auth/core/AuthHelpers';
import { I_Empresa } from '../../../interface/I_Empresa'

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Formato de e-mail incorreto')
        .min(3, 'Mínimo 3 símbolos')
        .max(50, 'Máximo de 50 símbolos')
        .required('O e-mail é obrigatório'),
    password: Yup.string()
        .min(3, 'Mínimo 3 símbolos')
        .max(50, 'Máximo de 50 símbolos')
        .required('A senha é obrigatória'),
})

const initialValues = {
    email: '',
    password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {

    const navigate = useNavigate();

    const [messageValidaUui, setMessageValidaUui] = useState<string | null>(null)
    const [isValidaUui, setIsValidaUui] = useState<boolean>(false)

    const [loading, setLoading] = useState(false)
    const { saveAuth, setCurrentUser, saveAuthUui, uuid } = useAuth()

    // authHelper.removeAuth();
    // authHelper.removeAuthUui();
    const { uui } = useParams();

    const { setRoles, setRoutesMenu, setPermissions, setNavigate, setEmpresaAssinante } = usePermissionStore();

    useEffect(() => {
        const validateUui = async () => {
            if (!uui && !uuid) {
                setMessageValidaUui('Token ausente na URL.')
                return
            }

            const tokenToValidate = typeof uui === "string" ? uui : typeof uuid === "string" ? uuid : null;

            if (!tokenToValidate) {
                console.error("Nenhum token válido foi encontrado.");
                setIsValidaUui(false);
                setMessageValidaUui("Nenhum token válido foi encontrado.");
                return;
            }

            try {
                const responseUuid = await validaUuid(tokenToValidate);

                if (responseUuid && responseUuid?.data.isOk) {
                    setMessageValidaUui('Token validado com sucesso, entre com seu email e senha');
                    setIsValidaUui(true);
                } else {
                    setMessageValidaUui('Token inválido.');
                    setIsValidaUui(false);
                }
            } catch (error) {
                console.error('Erro ao validar o token:', error)
                setMessageValidaUui('Erro ao validar o token.');
                setIsValidaUui(false);
            }
        }

        validateUui()
    }, [uui, uuid, isValidaUui])

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values: any, { setStatus, setSubmitting }: any) => {
            setLoading(true)
            try {
                const { data: auth } = await login(values.email, values.password, uui);
                console.log('login', auth)
                let hasUuid = uui;
                saveAuthUui({ uui: uui });

                if (!uui) {
                    hasUuid = String(uuid);
                    saveAuthUui(uuid);
                    // navigate(`dashboard/access/${uui}`);
                }

                // const { api_token, refreshToken } = auth;


                // const permissionStore = usePermissionStore();
                // Despachar a ação setFilter com os dados do filtro
                // dispatch(setAuthSlice({
                //   api_token,
                //   refreshToken: refreshToken || ''
                // }));

                saveAuth(auth)
                const response = await permissionsUser();
                const permissions = response.data.allowedsPermission ? response.data.allowedsPermission : [];
                const allowedsRoutes = response.data.allowedsRoutes ? response.data.allowedsRoutes : [];
                const roles = response.data.roles ? response.data.roles : null;

                setRoles(roles || '');
                setPermissions(permissions || []);
                setRoutesMenu(allowedsRoutes || []);

                const { data: user } = await getUserByToken(auth.api_token)
                setCurrentUser(user);

                if (!auth.empresa) {
                    setEmpresaAssinante(auth?.empresa);
                }


                setNavigate('dashboard', true);
                navigate(`dashboard/access/${hasUuid}`);
                // if (typeof uui != 'undefined') {
                //     saveAuthUui({ uui: uui });
                //     navigate(`dashboard/access/${uui}`);
                // } else {
                //     saveAuthUui(uuid);
                //     navigate(`dashboard/access/${uuid}`);
                // }


            } catch (error) {
                console.error(error)
                saveAuth(undefined)
                setStatus('Os detalhes de login estão incorretos ou uui invalido')
                setSubmitting(false)
                setLoading(false)
            }
        },
    });

    return (
        <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            {/* begin::Heading */}
            <div className='text-center mb-11'>
                <h1 className='text-dark fw-bolder mb-3'>Login</h1>
                {/* <div className='text-gray-500 fw-semibold fs-6'>Financeiro</div> */}
            </div>

            {messageValidaUui && (
                <div
                    className={`alert ${messageValidaUui.includes('sucesso') ? 'alert-success' : 'alert-danger'
                        }`}
                >
                    {messageValidaUui}
                </div>
            )}
            {/* begin::Heading */}

            {/* {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
            continue.
          </div>
        </div>
      )} */}

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                <input
                    placeholder='Email'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                    type='email'
                    name='email'
                    autoComplete='off'
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.email}</span>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-3'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Senha</label>
                <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                            'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                            'is-valid': formik.touched.password && !formik.errors.password,
                        }
                    )}
                />
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Wrapper */}
            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                <div />

                {/* begin::Link */}
                {/* <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link> */}
                {/* end::Link */}
            </div>
            {/* end::Wrapper */}

            {/* begin::Action */}
            <div className='d-grid mb-10'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting || !formik.isValid || !isValidaUui}
                >
                    {!loading && <span className='indicator-label'>Continue</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Por favor aguarde...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
            {/* end::Action */}

            <div className='text-gray-500 text-center fw-semibold fs-6'>
                {/* Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link> */}
            </div>
        </form>
    )
}
