import { AxiosError, AxiosResponse } from "axios";
import BaseServiceHttp from "../../_metronic/helpers/functions/http/BaseServiceHttp";
import { IResponse } from "../interface/I_General";
import { useAuth } from "../modules/auth";

import { getToken } from './../redux/api/auth/getToken';
import * as authHelper from './../modules/auth/core/AuthHelpers';

const uuid = authHelper.getAuthUui();
const dataLocalStorage = getToken();

export const permissionsUser = async () => {

    const customConfig: any = {
        method: 'post',
        url: `/module/permissions`,
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token}`,
            'X-UUID-Assinante': uuid ? String(uuid) : ''
        }
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro
            const { response } = error;
            let statusResponse: any;
            if (response) {
                const { status, data } = response;
                statusResponse = status;
            }

            return {
                data: {
                    success: false,
                    data: [],
                    status: statusResponse,
                    message: 'Ocorreu um erro ao tentar recuperar permissões'
                }
            }
        });
    return response;
}