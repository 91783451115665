
import axios from "axios";
import { AuthType, IResponse } from "../interface/I_General";
import { ApiResponse } from "../interface/IUser";

import { getToken } from './../redux/api/auth/getToken';
import * as authHelper from './../modules/auth/core/AuthHelpers';

const uuid = authHelper.getAuthUui();
const dataLocalStorage = getToken();

const API_URL = process.env.REACT_APP_API_URL

export const listUser = async (auth: AuthType) => {
    try {

        const response = await axios.post<IResponse>(`${API_URL}/module/listUser`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token || auth?.api_token}`,
                    'X-UUID-Assinante': uuid ? String(uuid) : ''
                }
            }
        );

        return response;
    } catch (error) {
        throw error;
    }
}

export const ativarInativarUser = async (data: any, auth: AuthType) => {
    try {
        const response = await axios.post<IResponse>(`${API_URL}/module/ativarInativarUser`,
            { data },
            {
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token || auth?.api_token}`,
                    'X-UUID-Assinante': uuid ? String(uuid) : ''
                }
            }
        );

        return response;
    } catch (error) {
        throw error;
    }
}

export async function registerUser(data: string, auth: AuthType) {
    try {

        const response = await axios.post<IResponse>(`${API_URL}/module/registerUser`,
            { data },
            {
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token || auth?.api_token}`,
                    'X-UUID-Assinante': uuid ? String(uuid) : ''
                }
            }
        );

        return response;
    } catch (error) {
        throw error;
    }

}

export async function updateUser(data: any, auth: AuthType) {
    try {

        const response = await axios.post<IResponse>(`${API_URL}/module/updateUser`,
            { data },
            {
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token || auth?.api_token}`,
                    'X-UUID-Assinante': uuid ? String(uuid) : ''
                }
            }
        );

        return response;
    } catch (error) {
        throw error;
    }

}

export async function getUserById(id: string, auth: AuthType) {
    try {

        const response = await axios.post<ApiResponse>(`${API_URL}/module/getUserById/${id}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token || auth?.api_token}`,
                    'X-UUID-Assinante': uuid ? String(uuid) : ''
                }
            }
        );

        return response;
    } catch (error) {
        throw error;
    }

}