/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import { DocumentationWrapper } from '../pages/suport/DocumentationWrapper'
import * as authHelper from './../modules/auth/core/AuthHelpers'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth();

  const uui = authHelper.getAuthUui();

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout/:uui/*' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route path='documentation/*' element={<DocumentationWrapper />} />
              <Route index element={<Navigate to={`/dashboard/access/${uui}`} />} />
            </>
          ) : (
            <>
              {/* Página de autenticação */}
              {/* {uui ? (
                <Route path={`auth/${uui}/*`} element={<AuthPage />} />
              ) : (
                <Route path="auth/:uui/*" element={<AuthPage />} />
              )} */}

              <Route path="auth/:uui/*" element={<AuthPage />} />
              {/* Redireciona para a página de autenticação com UUID ou sem */}
              <Route path="*" element={<Navigate to={uui ? `/auth/${uui}` : '/auth'} />} />
              {/* <Route path="auth/:uui/*" element={<AuthPage />} />
              <Route path="auth" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} /> */}
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
