import axios, { AxiosError, AxiosResponse } from 'axios'
import BaseServiceHttp from '../../_metronic/helpers/functions/http/BaseServiceHttp';
import { IResponse, AuthType } from '../interface/I_General';
import { FormValues } from '../interface/I_Parcel';
import { ChangeInMass } from '../types/T_General';

import { getToken } from './../redux/api/auth/getToken';
import * as authHelper from './../modules/auth/core/AuthHelpers';

const uuid = authHelper.getAuthUui();
const dataLocalStorage = getToken();

const API_URL = process.env.REACT_APP_API_URL;

// inseri uma parcela
export async function insertParcelAPI(form: FormValues, idContract: number | undefined | string, auth: AuthType) {
    const customConfig: any = {
        method: 'post',
        url: `/module/insertParcel`,
        data: {
            form,
            idContract
        },
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token || auth?.api_token}`,
            'X-UUID-Assinante': uuid ? String(uuid) : ''
        }
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro
            const { response } = error;
            let statusResponse: any;
            if (response) {
                const { status, data } = response;
                statusResponse = status;
            }
            console.log('erros response', response)
            return {
                data: {
                    success: false,
                    data: [],
                    status: statusResponse,
                    message: 'Ocorreu um erro ao tentar criar parcela'
                }
            }
        });
    return response;

}

// editar uma parcela
export async function editParcelAPI(form: FormValues, auth: AuthType) {
    const customConfig: any = {
        method: 'post',
        url: `/module/editParcel`,
        data: {
            form
        },
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token || auth?.api_token}`,
            'X-UUID-Assinante': uuid ? String(uuid) : ''
        }
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro
            const { response } = error;
            let statusResponse: any;
            if (response) {
                const { status, data } = response;
                statusResponse = status;
            }

            return {
                data: {
                    success: false,
                    data: [],
                    status: statusResponse,
                    message: 'Ocorreu um erro ao tentar editar parcela'
                }
            }
        });
    return response;

}

// deleta uma ou mais parcelas
export async function deleteParcelAPI(ids: string[], auth: AuthType) {
    const customConfig: any = {
        method: 'post',
        url: `/module/deleteParcel`,
        data: {
            ids
        },
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token || auth?.api_token}`,
            'X-UUID-Assinante': uuid ? String(uuid) : ''
        }
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro
            const { response } = error;
            let statusResponse: any;
            if (response) {
                const { status, data } = response;
                statusResponse = status;
            }

            return {
                data: {
                    success: false,
                    data: [],
                    status: statusResponse,
                    message: 'Ocorreu um erro ao tentar deletar parcela'
                }
            }
        });
    return response;
}

// deleta condiação de pagamento
export async function deleteConditionAPI(id: any, auth: AuthType) {
    try {

        // let headers = {};
        // if (auth && auth.api_token) {
        //     headers = { Authorization: `Bearer ${auth.api_token}` };
        // }

        const response = await axios.post<IResponse>(`${API_URL}/module/deleteConditionPayment`,
            { id },
            {
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token || auth?.api_token}`,
                    'X-UUID-Assinante': uuid ? String(uuid) : ''
                }
            }
        );

        return response;
    } catch (error) {
        throw error;
    }
}

// altera dados da parcela em massa
export async function changeParcelInMassaApi(ids: string[], data: ChangeInMass[], auth: AuthType) {
    const customConfig: any = {
        method: 'post',
        url: `/module/changeParcelInMassa`,
        data: {
            ids,
            data
        },
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token || auth?.api_token}`,
            'X-UUID-Assinante': uuid ? String(uuid) : ''
        }
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro
            const { response } = error;
            let statusResponse: any;
            if (response) {
                const { status, data } = response;
                statusResponse = status;
            }

            return {
                data: {
                    success: false,
                    data: [],
                    status: statusResponse,
                    message: 'Ocorreu um erro ao tentar atualiazar parcelas em massa'
                }
            }
        });
    return response;

}

export async function calcularAntecipacaoParcela(form: any, auth: AuthType) {

    try {

        // let headers = {};
        // if (auth && auth.api_token) {
        //     headers = { Authorization: `Bearer ${auth.api_token}` };
        // }

        const response = await axios.post<IResponse>(`${API_URL}/module/calcularAntecipacaoParcela`,
            { form },
            {
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token || auth?.api_token}`,
                    'X-UUID-Assinante': uuid ? String(uuid) : ''
                }
            }
        );

        return response;
    } catch (error) {
        throw error;
    }

}

export async function simulationQuitacao(form: any, auth: AuthType) {

    try {

        // let headers = {};
        // if (auth && auth.api_token) {
        //     headers = { Authorization: `Bearer ${auth.api_token}` };
        // }

        const response = await axios.post<IResponse>(`${API_URL}/module/simulationQuitacao`,
            { form },
            {
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token || auth?.api_token}`,
                    'X-UUID-Assinante': uuid ? String(uuid) : ''
                }
            }
        );

        return response;
    } catch (error) {
        throw error;
    }

}

export async function simulationDistrato(form: any, auth: AuthType) {

    try {

        // let headers = {};
        // if (auth && auth.api_token) {
        //     headers = { Authorization: `Bearer ${auth.api_token}` };
        // }

        const response = await axios.post<IResponse>(`${API_URL}/module/simulationDistrato`,
            { form },
            {
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token || auth?.api_token}`,
                    'X-UUID-Assinante': uuid ? String(uuid) : ''
                }
            }
        );

        return response;
    } catch (error) {
        throw error;
    }

}

export const encaminharBoleto = async (idParcel: any, auth: AuthType) => {

    try {

        // let headers = {};
        // if (auth && auth.api_token) {
        //     headers = { Authorization: `Bearer ${auth.api_token}` };
        // }

        const response = await axios.post<IResponse>(`${API_URL}/module/encaminharBoleto`,
            { idParcel },
            {
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token || auth?.api_token}`,
                    'X-UUID-Assinante': uuid ? String(uuid) : ''
                }
            }
        );

        return response;
    } catch (error) {
        throw error;
    }
}

export const documentsParcel = async (id_parcel: any, auth: AuthType) => {

    try {

        // let headers = {};
        // if (auth && auth.api_token) {
        //     headers = { Authorization: `Bearer ${auth.api_token}` };
        // }

        const response = await axios.post<IResponse>(`${API_URL}/module/documentsParcel`,
            { id_parcel },
            {
                headers: {
                    Authorization: `Bearer ${dataLocalStorage?.token || auth?.api_token}`,
                    'X-UUID-Assinante': uuid ? String(uuid) : ''
                }
            }
        );

        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteDocumentParcel = async (form_ids: any) => {

    const customConfig: any = {
        method: 'post',
        url: `/module/deleteDocumentParcel`,
        data: {
            form_ids
        },
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token}`,
            'X-UUID-Assinante': uuid ? String(uuid) : ''
        }
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro
            const { response } = error;
            let statusResponse: any;
            if (response) {
                const { status, data } = response;
                statusResponse = status;
            }

            return {
                data: {
                    success: false,
                    data: [],
                    status: statusResponse,
                    message: 'Ocorreu um erro ao tentar deletar um documento'
                }
            }
        });
    return response;
}

export const getBoleCodeItau = async (form: any) => {

    const customConfig: any = {
        method: 'post',
        url: `/module/getBoleCodeItau`,
        data: {
            ...form
        },
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token}`,
            'X-UUID-Assinante': uuid ? String(uuid) : ''
        }
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro
            const { response } = error;
            let statusResponse: any;
            if (response) {
                const { status, data } = response;
                statusResponse = status;
            }

            return {
                data: {
                    success: false,
                    data: [],
                    status: statusResponse,
                    message: 'Ocorreu um erro ao tentar deletar um documento'
                }
            }
        });
    return response;
}

export const getBoleCodeItauPaginate = async (form_data: any) => {

    const customConfig: any = {
        method: 'post',
        url: `/module/getPixByPaginate`,
        data: {
            ...form_data
        },
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token}`,
            'X-UUID-Assinante': uuid ? String(uuid) : ''
        }
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro
            const { response } = error;
            let statusResponse: any;
            if (response) {
                const { status, data } = response;
                statusResponse = status;
            }

            return {
                data: {
                    success: false,
                    data: [],
                    status: statusResponse,
                    message: 'Ocorreu um erro ao tentar deletar um documento'
                }
            }
        });
    return response;
}

export const downloadBolecodeItau = async (form: any) => {

    const customConfig: any = {
        method: 'post',
        url: `/module/downloadBolecode`,
        data: {
            ...form
        },
        headers: {
            Authorization: `Bearer ${dataLocalStorage?.token}`,
            'X-UUID-Assinante': uuid ? String(uuid) : ''
        }
    };

    const http = BaseServiceHttp<IResponse>();

    const response = await http(customConfig)
        .then((response: AxiosResponse<any>) => {
            return response;
        })
        .catch((error: AxiosError) => {
            // throw error; // Rejeita a Promise para propagar o erro
            const { response } = error;
            let statusResponse: any;
            if (response) {
                const { status, data } = response;
                statusResponse = status;
            }

            return {
                data: {
                    success: false,
                    data: [],
                    status: statusResponse,
                    message: 'Ocorreu um erro ao tentar fazer download.'
                }
            }
        });
    return response;
}

