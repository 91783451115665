import clsx from 'clsx'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher } from '../../../partials'
import { useLayout } from '../../core'
import { useAuth } from '../../../../app/modules/auth'
import { isUri } from '../../../helpers/functions/url'
import { useFetchListProductQuery } from '../../../../app/redux/api/product/listProduct'
import { useEffect, useState } from 'react'
import { Option, OptionProductNav } from '../../../../app/interface/I_General'
import Select from "react-select";
import { useDispatch } from 'react-redux'
import { setFilterProduct } from '../../../../app/redux/filter/productSlice'
import { HttpRequestModifyLoadButton } from '../../../helpers/functions/http/HttpRequestModifyLoadButton'
import { useLocation } from 'react-router-dom'


const itemClass = 'ms-1 ms-lg-3'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const { config } = useLayout()
  const { currentUser, logout } = useAuth()

  const location = useLocation()

  // HttpRequestIntercept()
  HttpRequestModifyLoadButton()
  const dispatch = useDispatch();

  interface OptionSelected {
    id_product: string;
  }
  const { data: products, error, isLoading }: any = useFetchListProductQuery(null);
  const [optionsProduct, setOptionsProduct] = useState<OptionProductNav[]>([]);
  const [formData, setFormData] = useState({
    product: {
      id_product: '',
    }
  });

  const handleSelectChange = <K extends keyof OptionSelected>(selectedOption: OptionProductNav | null, fieldName: K) => {
    const value = selectedOption ? selectedOption.value : "0";

    setFormData((prevState) => ({
      ...prevState,
      product: {
        ...prevState.product,
        [fieldName]: value.toString(),
      },
    }));

  };

  const fetchProducts = () => {

    if (products && Array.isArray(products)) {
      const allOption = {
        value: '',
        label: 'Todos',
      };

      const newOptionsProduct = [allOption, ...products.map((product: { id: any; name: string; beneficiary: string }) => ({
        value: product.id.toString(),
        label: (
          <div className="d-flex align-items-center">

            <div className="ms-5">
              <span className="fw-bold text-gray-600">{product.name}</span>
              <div className="fs-7 text-muted">{product.beneficiary}</div>
            </div>
          </div>
        )
      }))];

      setOptionsProduct(newOptionsProduct);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [products]);

  useEffect(() => {

    dispatch(setFilterProduct(formData));
  }, [formData.product]);


  return (
    <>
      <div className="
            menu 
            menu-rounded  
            menu-column 
            menu-lg-row 
            my-5 
            my-lg-0 
            align-items-stretch 
            fw-semibold
            px-2 px-lg-0
        ">
        <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="bottom-start" className="menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2">
          {/* <span className="menu-link"><span className="menu-title">Help</span><span className="menu-arrow d-lg-none"></span></span> */}
          {/* <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-lg-2 py-lg-4 w-lg-200px">
                <div className="menu-item">
                    
                </div>
            </div> */}
          <div className="col-md-12">
            {/* <label className='form-label fw-bold'>Produto</label> */}
            <div className=''>
              <Select
                styles={{ container: (base: any) => ({ ...base, zIndex: 12 }) }}
                className="w-450px"
                placeholder="Selecione um produto"
                value={optionsProduct.find(option => option.value === formData.product.id_product) || null}
                onChange={(selectedOption: any) => {
                  handleSelectChange(selectedOption, "id_product");
                  dispatch(setFilterProduct(formData)); // Dispatch da ação quando o produto é selecionado
                }}
                options={optionsProduct}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='app-navbar flex-shrink-0'>
        <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
          {/* <Search /> */}
        </div>

        {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div id='kt_activities_toggle' className={btnClass}>
          <KTSVG path='/media/icons/duotune/general/gen032.svg' className={btnIconClass} />
        </div>
      </div> */}

        {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
        >
          <KTSVG path='/media/icons/duotune/general/gen022.svg' className={btnIconClass} />
        </div>
        <HeaderNotificationsMenu />
      </div> */}

        {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div> */}

        <div className={clsx('app-navbar-item', itemClass)}>
          <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
        </div>

        <div className={clsx('app-navbar-item', itemClass)}>
          <div
            className={clsx('cursor-pointer w-35px h-35px d-flex align-items-center bg-opacity-50 bg-primary symbol symbol-35px symbol-md-40px', userAvatarClass)}
            data-kt-menu-trigger="{default: 'click'}"
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
          >
            {
              isUri(currentUser?.photo) ? (
                <img src={currentUser?.photo} alt={currentUser?.fullname} />
              ) : (

                <div className="d-flex justify-content-center col-md-12 text-light-secondary">
                  <span className="align-self-center text-center fs-5">{currentUser?.name_short}</span>
                </div>
              )
            }
            {/* <img src={currentUser?.photo} alt='' /> */}
          </div>
          <HeaderUserMenu />
        </div>

        {config.app?.header?.default?.menu?.display && (
          <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
            <div
              className='btn btn-icon btn-active-color-primary w-35px h-35px'
              id='kt_app_header_menu_toggle'
            >
              <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export { Navbar }
