
import { create } from 'zustand'
import { permissionsUser } from '../request/permission';
import { I_Empresa } from '../interface/I_Empresa';

export interface I_RouteMenu {
    to: string;
    allowedTo: string;
    title: string;
    icon: string;
    intl: string;
}

interface I_StoreState {
    access: boolean;
    list: boolean;
    create: boolean;
    edit: boolean;
    delete: boolean;
    import: boolean;
    export: boolean;
    dev: boolean;
    banks: string[];
    permissions: string[];
    routesMenu: I_RouteMenu[];
    roles: string;
    currentRoute: string | null;
    empresa: I_Empresa | undefined;
    setAccess: (access: boolean) => void;
    setList: (list: boolean) => void;
    setCreate: (create: boolean) => void;
    setEdit: (edit: boolean) => void;
    setDelete: (_delete: boolean) => void;
    setImport: (_import: boolean) => void;
    setExport: (_export: boolean) => void;
    setDev: (dev: boolean) => void;
    setBanks: (banks: string[]) => void;
    setPermissions: (permissions: []) => void;
    setRoutesMenu: (routesMenu: I_RouteMenu[]) => void;
    setRoles: (roles: string) => void;
    setEmpresaAssinante: (empresa: I_Empresa) => void;
    setNavigate: (route: string, get_permission?: boolean) => void;
}

const usePermissionStore = create<I_StoreState>((set) => ({
    access: false,
    list: false,
    create: false,
    edit: false,
    delete: false,
    import: false,
    export: false,
    dev: false,
    banks: [],
    permissions: [],
    routesMenu: [],
    roles: '',
    currentRoute: 'dashboard',
    empresa: undefined,
    setAccess: (access) => set({ access: access }),
    setList: (list) => set({ list: list }),
    setCreate: (create) => set({ create: create }),
    setEdit: (edit) => set({ edit: edit }),
    setDelete: (_delete) => set({ delete: _delete }),
    setImport: (_import) => set({ import: _import }),
    setExport: (_export) => set({ export: _export }),
    setDev: (dev) => set({ dev: dev }),
    setBanks: (banks) => set({ banks: banks }),
    setPermissions: (permissions: []) => set({ permissions: permissions }),
    setRoutesMenu: (routesMenu: I_RouteMenu[]) => set({ routesMenu: routesMenu }),
    setRoles: (roles: string) => set({ roles: roles }),
    setEmpresaAssinante: (empresa: I_Empresa) => set({ empresa: empresa }),
    // setNavigate: async (route:string) => set({ currentRoute: route }),
    setNavigate: async (route: string, get_permission: boolean = true) => {
        try {

            if (get_permission) {
                const response = await permissionsUser();
                const permissions = response.data.allowedsPermission ? response.data.allowedsPermission : [];
                const allowedsRoutes = response.data.allowedsRoutes ? response.data.allowedsRoutes : [];
                const roles = response.data.roles ? response.data.roles : null;

                set({ permissions: permissions });
                set({ routesMenu: allowedsRoutes });
                set({ roles: roles });
            }

            set({ currentRoute: route ?? 'dashboard' });
        } catch (error) {
            console.error('Erro ao definir permissões:', error);
        }
    },
}));

export default usePermissionStore;
