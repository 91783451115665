/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
// import { useIntl } from 'react-intl'
// import { toAbsoluteUrl } from '../../../_metronic/helpers'
import TableAdvanced from '../../../_metronic/layout/components/tables/TableAdvanced'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
// import TableContract from '../contrato/TableContract'
// import {HeaderForTypeContract} from '../../../_metronic/partials/contract/HeaderTableContract'

import getHeaderForTypeContract from "../../../_metronic/partials/contract/HeaderTableContract";
import { TableOptions } from '../../interface/I_Table'
import { IFormUnit } from '../../interface/I_Unit'
import { FormUnit } from './modal/FormUnit'

const profileBreadCrumbs: Array<PageLink> = [
    {
        title: 'Home',
        path: '/dashboard/access',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const LotePage: FC = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formValues, setFormValues] = useState<IFormUnit>({
        id: 0,
        id_product: 0,
        name: ''
    });

    const [tableData, setTableData] = useState<IFormUnit[]>([]);

    /** ao clicar no botão inserir */
    const handleInsert = () => {
        setFormValues({
            id: 0,
            id_product: 0,
            name: ''
        });

        setIsModalOpen(true);
    };

    /** ao clicar no botão editar */
    const handleEdit = (row: any) => {

        setFormValues({
            id: row.id || 0,
            id_product: row.id_product || 0,
            name: row.name || ''
        });

        setIsModalOpen(true);
    };

    /** fecha modal */
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handlerCallbackFormSubmit = (formData: IFormUnit) => {
        if (formData.hasOwnProperty('id') && formData.id !== "0" && formData.id !== null && formData.id !== "" && formData.name !== "") {
            setTableData([formData]);
            setIsModalOpen(false);
        }

    }

    const optionsUnit: TableOptions = {
        // id:id, // opcional
        header: getHeaderForTypeContract('unit', handleEdit),
        iInterface: 'IUnit',
        endPoint: `tableUnidade`,
        methodEndPoint: 'post',
        title: 'Lista de Unidade',
        classHeadTd: 'min-w-150px',
        classTbody: 'fw-semibold text-gray-800',
        classtbodyTr: '',
        classTbodyTd: '',
        isHidePaginate: true,
        funDeleteInMass: 'deleteUnitInMassa',
        // funChangeInMass: 'changeUnitInMassa',
        textDelete: 'Unidades',
        // verifyPermission: 'unit'
    };

    return (
        <>
            <div className="app-container  container-xxl ">
                <div className="d-flex flex-column flex-lg-row ms-n11">
                    {/* <TableContract api={`tableLote`} type={`lote`}/> */}
                    <TableAdvanced
                        options={optionsUnit}
                        tableData={tableData}
                        handleInsert={handleInsert}
                    />
                    {isModalOpen &&
                        <FormUnit
                            formValues={formValues}
                            closeModal={closeModal}
                            onFormSubmit={handlerCallbackFormSubmit}
                        />
                    }
                </div>
            </div>
        </>
    )
}

const LotesWrapper: FC = () => {
    // const intl = useIntl()
    return (
        <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Lotes</PageTitle>
            {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
            <LotePage />
        </>
    )
}

export { LotesWrapper }


